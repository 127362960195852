/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  exercises: [],
  currentExercise: {},
  currentExerciseProgression: {},

};

// getters
const getters = {
  getExercises: (state) => {
    return state.exercises;
  },
  getCurrentExercise: (state) => {
    return state.currentExercise;
  },
  getCurrentExerciseProgression: (state) => {
    return state.currentExerciseProgression;
  },
};

// actions
const actions = {
  //get all new registration
  fetchExercises({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/exercises")
        .then((res) => {
          commit("FETCH_EXERCISES", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchExercise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/exercises/" + id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 0);
          commit("FETCH_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchExerciseProgression({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/exercises/progression/" + id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_EXERCISE_PROGRESSION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserExerciseProgression({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/exercises/progression/" +
            payload.exerciseId +
            "/" +
            payload.userId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_EXERCISE_PROGRESSION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/exercises", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("CREATE_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/exercises/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/exercises/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("EDIT_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  toggleActiveExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/exercises/toggleActive/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("EDIT_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserExerciseDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/exercises/details/" +
            payload.exerciseId +
            "/" +
            payload.userId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_EXERCISE_DETAILS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  CREATE_EXERCISE(state, data) {
    state.exercises.unshift(data);
  },
  FETCH_EXERCISES(state, data) {
    // console.log("FETCH_EXERCISES", data);
    state.exercises = data;
  },
  FETCH_EXERCISE(state, data) {
    state.currentExercise = data;
  },
  FETCH_EXERCISE_PROGRESSION(state, data) {
    state.currentExerciseProgression = data;
  },
  DELETE_EXERCISE(state, data) {
    let index = state.exercises.findIndex((item) => item.id === data.id);
    state.exercises.splice(index, 1);
  },
  FETCH_EXERCISE_DETAILS(){
    //niks
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
