<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="``"
    :disabled="item.disabled"
    v-show="!item.hide"
  >
    <v-badge
      bordered
      overlap
      :dot="$vuetify.breakpoint.mdAndDown"
      color="red darken-2"
      offset-x="25"
      offset-y="15"
      :icon="$vuetify.breakpoint.mdAndDown ? '' : 'mdi-email'"
      v-if="item.title == 'Berichten'"
      :value="hasMessages"
    >
      <v-list-item-icon
        v-if="item.icon"
        style="border-radius: 20px"
        class="mobilefix"
      >
        <v-icon v-text="item.icon" />
      </v-list-item-icon>
    </v-badge>

    <v-badge
      bordered
      overlap
      :dot="$vuetify.breakpoint.mdAndDown"
      color="red darken-2"
      offset-x="25"
      offset-y="15"
      :icon="$vuetify.breakpoint.mdAndDown ? '' : 'mdi-new-box'"
      v-else-if="item.titleType == 'websiteLink'"
    >
      <v-list-item-icon
        v-if="item.icon"
        style="border-radius: 20px"
        class="mobilefix"
      >
        <v-icon v-text="item.icon" />
      </v-list-item-icon>
    </v-badge>

    <v-list-item-icon v-else v-show="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<style scoped>
.mobilefix {
  padding: 4px 1px 1px 1px !important;
}
</style>

<script>
import Themeable from "vuetify/lib/mixins/themeable";
import { mapGetters } from "vuex";

export default {
  name: "BaseItem",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
    ...mapGetters({
      hasMessages: "chats/getHasMessages",
    }),
  },
};
</script>
