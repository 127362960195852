import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Vuetify from "../plugins/vuetify";

//modules
import users from "./modules/users.js";
import exercises from "./modules/exercises.js";
import trainings from "./modules/trainings.js";
import packages from "./modules/packages.js";
import chats from "./modules/chats.js";
import readings from "./modules/readings.js";
import subscriptions from "./modules/subscriptions.js";
import uploads from "./modules/uploads.js";
import nutritions from "./modules/nutritions.js";
import movementanalyses from "./modules/movementanalyses.js";
import questions from "./modules/questions.js";
import stats from "./modules/stats.js";
import admincoaches from "./modules/admincoaches.js";
import appointments from "./modules/appointments.js";
import settings from "./modules/settings.js";
import coaches from "./modules/coaches.js";
import emails from "./modules/emails.js";
import food from "./modules/food.js";
import auth from "./modules/auth.js";
import localstorage from "./modules/localstorage.js";
import musclegroups from "./modules/musclegroups.js";
import logger from "./modules/logger.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "#000000", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
    loaderEnabled: false,
    messages: [],
    memberPagination: {},
    memberPaginationLocked: false,
    theme: {},
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    TOGGLE_LOADER(state) {
      state.loaderEnabled = !state.loaderEnabled;
    },
    ADD_MESSAGE(state, data) {
      state.messages = [];
      state.messages.unshift(data);
    },
    SET_MEMBER_PAGINATION(state, data) {
      state.memberPagination = data;
    },
    SET_MEMBER_PAGINATION_LOCKED(state, data) {
      state.memberPaginationLocked = data;
    },
    SET_THEME(state, data) {
      state.theme = data;
      //set vuetity instanced theme
      Vuetify.framework.theme.dark = data.darkMode;

      if (data.darkMode) {
        Vuetify.framework.theme.themes.dark.primary = data.primaryColor;
        Vuetify.framework.theme.themes.dark.secondary = data.secondaryColor;
        Vuetify.framework.theme.themes.dark.info = data.secondaryColor;
        Vuetify.framework.theme.themes.dark.success = data.success;
        Vuetify.framework.theme.themes.dark.accent = data.accent;
        Vuetify.framework.theme.themes.dark.default = data.default;
        Vuetify.framework.theme.themes.dark.complement = data.complement;
      } else {
        Vuetify.framework.theme.themes.light.primary = data.primaryColor;
        Vuetify.framework.theme.themes.light.secondary = data.secondaryColor;
        Vuetify.framework.theme.themes.light.info = data.secondaryColor;
        Vuetify.framework.theme.themes.light.success = data.success;
        Vuetify.framework.theme.themes.light.accent = data.accent;
        Vuetify.framework.theme.themes.light.default = data.default;
        Vuetify.framework.theme.themes.light.complement = data.complement;
      }
    },
  },
  actions: {
    toggleLoader({ commit }) {
      commit("TOGGLE_LOADER");
    },
    addMessage({ commit }, payload) {
      commit("ADD_MESSAGE", payload);
    },
    setMemberPagination({ commit }, payload) {
      commit("SET_MEMBER_PAGINATION", payload);
    },
    setMemberPaginationLocked({ commit }, payload) {
      commit("SET_MEMBER_PAGINATION_LOCKED", payload);
    },
    fetchCoachTheme({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("api/Themes")
          .then((res) => {
            setTimeout(() => resolve(res.data), 250);
            commit("SET_THEME", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCoachTheme({ commit }, payload) {
      commit("SET_THEME", payload);
      return new Promise((resolve, reject) => {
        axios
          .post("api/Themes", payload)
          .then((res) => {
            setTimeout(() => resolve(res.data), 250);
            //commit("SET_THEME", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchRegistrationTheme({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("api/Themes/" + payload.userId)
          .then((res) => {
            setTimeout(() => resolve(res.data), 250);
            commit("SET_THEME", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getLoaderState: (state) => {
      return state.loaderEnabled;
    },
    getMessages: (state) => {
      return state.messages;
    },
    getMemberPagination: (state) => {
      return state.memberPagination;
    },
    getMemberPaginationLocked: (state) => {
      return state.memberPaginationLocked;
    },
    getTheme: (state) => {
      return state.theme;
    },
  },
  modules: {
    users: users,
    exercises: exercises,
    trainings: trainings,
    packages: packages,
    chats: chats,
    readings: readings,
    subscriptions: subscriptions,
    uploads: uploads,
    nutritions: nutritions,
    movementanalyses: movementanalyses,
    questions: questions,
    stats: stats,
    admincoaches: admincoaches,
    appointments: appointments,
    settings: settings,
    coaches: coaches,
    emails: emails,
    food: food,
    auth: auth,
    localstorage: localstorage,
    musclegroups: musclegroups,
    logger: logger,
  },
});
