<template>
  <v-row
    :class="[bottomMargin ? 'page-breadcrumb' : 'page-breadcrumb-no-margin']"
  >
    <v-col cols="12" sm="12" class="pa-0">
      <v-card
        class="px-2 custom-shaodow"
        :class="{ 'py-2': !breadcrumbsVisible }"
      >
        <v-toolbar dense flat class="transparent pa-0" height="40px">
          <v-toolbar-title
            :class="{ 'pb-2': $vuetify.breakpoint.mdAndDown }"
            class="pt-3"
          >
            <v-btn
              icon
              class="pb-1"
              @click="back()"
              v-if="returnRoute.length >= 1"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <span class="text--primary">{{ title }}</span>
          </v-toolbar-title>
          <v-toolbar-title
            class="toobar-extension transparent pb-3"
            slot="extension"
            v-if="breadcrumbsVisible"
          >
            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="breadcrumbs">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>

            <slot></slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip
            bottom
            v-model="openInfoTooltip"
            v-if="infoBoxVisible"
            :open-on-hover="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="toggleTooltip()"
                ><v-icon>mdi-information-outline</v-icon></v-btn
              ></template
            >
            <span>{{ infoBoxText }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    returnRoute: { Type: String, default: "" },
    bottomMargin: { Type: Boolean, default: true },
    breadcrumbsVisible: { Type: Boolean, default: true },
    infoBoxVisible: { Type: Boolean, default: false },
    infoBoxText: { Type: String, default: "" },
  },
  data: () => ({ openInfoTooltip: false }),
  methods: {
    back() {
      this.$router.push({
        name: this.returnRoute,
      });
    },
    toggleTooltip() {
      this.openInfoTooltip = !this.openInfoTooltip;
    },
  },
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -24px -25px 27px -25px;
}
.page-breadcrumb-no-margin {
  margin: -24px -25px 0px -25px;
}
</style>