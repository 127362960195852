/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  products: [],
  hasProducts: true,
  foodSchedule: {},
  productMeasurements: [],
  productDetail: {},
  defaultMeasurements: [],
};

// getters
const getters = {
  getProducts: (state) => {
    return state.products;
  },
  getHasProducts: (state) => {
    return state.hasProducts;
  },
  getFoodSchedule: (state) => {
    return state.foodSchedule;
  },
  getProductMeasurements: (state) => {
    return state.productMeasurements;
  },
  getProductDetail: (state) => {
    return state.productDetail;
  },
  getDefaultMeasurements: (state) => {
    return state.defaultMeasurements;
  },
};

// actions
const actions = {
  //get all current coaches
  fetchProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/food/products/${payload.limit}/${payload.start}`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_PRODUCTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/products`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_PRODUCTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/food/products/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_PRODUCT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //FOOD
  fetchFoodSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/schedule`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateDayTypeSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/schedule/dayType`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_DAY_TYPE_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachFoodSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/schedule/coach`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProductMeasurement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/food/product/measurement/${payload.id}`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_PRODUCT_MEASUREMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchFullProductMeasurement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/food/product/measurement/full/${payload.id}`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_PRODUCT_MEASUREMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addProductToSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/schedule/product`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_PRODUCT_TO_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editProductinSchedule({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/food/schedule/product/${payload.scheduleProductId}`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_PRODUCT_IN_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteScheduleProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`api/food/schedule/product/${payload.id}`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_PRODUCT_FROM_SCHEDULE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Product detail
  fetchProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/food/product/${payload.id}`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_PRODUCT_DETAIL", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateProductImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/food/product/update/${payload.id}`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_PRODUCT_IMAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/food/product/${payload.id}`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_PRODUCT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Product portie add
  addProductMeasurement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/product/portion`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_PRODUCT_MEASUREMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addProductMeasurementCoachee({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/food/product/portion/coachee`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_PRODUCT_MEASUREMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDefaultMeasurement({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/food/product/measurement/default`)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_DEFAULT_MEASUREMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_PRODUCTS(state, data) {
    if (state.products.length <= 0) {
      state.products = data;
    } else {
      //add via push
      let producten = state.products.concat(data);
      state.products = producten;

      if (data.length <= 0) state.hasProducts = false;
      else state.hasProducts = true;

      //remove duplicates
      state.products = state.products.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
    }
  },
  CREATE_PRODUCTS(state, data) {
    state.products.unshift(data);
  },
  DELETE_PRODUCT(state, data) {
    let index = state.products.findIndex((item) => item.id === data.id);
    state.products.splice(index, 1);
  },
  FETCH_SCHEDULE(state, data) {
    state.foodSchedule = data;
  },
  EDIT_DAY_TYPE_SCHEDULE(state, data) {
    state.foodSchedule.dayType = data;
  },
  FETCH_PRODUCT_MEASUREMENTS(state, data) {
    state.productMeasurements = data;
  },
  ADD_PRODUCT_MEASUREMENT(state, data) {
    state.productMeasurements.unshift(data);
  },
  ADD_PRODUCT_TO_SCHEDULE(state, data) {
    let oldProducts = state.foodSchedule.products;
    oldProducts = oldProducts.concat(data.products);

    data.products = oldProducts;
    state.foodSchedule = data;
  },
  EDIT_PRODUCT_IN_SCHEDULE(state, data) {
    let product = data.products[0];
    state.foodSchedule.products = [
      ...state.foodSchedule.products.filter(
        (element) => element.scheduleProductId !== product.scheduleProductId
      ),
      product,
    ];

    //update nutriotons
    state.foodSchedule.calories = data.calories;
    state.foodSchedule.carbs = data.carbs;
    state.foodSchedule.fat = data.fat;
    state.foodSchedule.protein = data.protein;
  },
  DELETE_PRODUCT_FROM_SCHEDULE(state, data) {
    let id = data.scheduleProduct.id;
    let index = state.foodSchedule.products.findIndex(
      (item) => item.scheduleProductId === id
    );
    state.foodSchedule.products.splice(index, 1);

    //update nutriotons
    state.foodSchedule.calories = data.calories;
    state.foodSchedule.carbs = data.carbs;
    state.foodSchedule.fat = data.fat;
    state.foodSchedule.protein = data.protein;
  },
  FETCH_PRODUCT_DETAIL(state, data) {
    state.productDetail = data;
  },
  UPDATE_PRODUCT(state, data) {
    state.products = [
      ...state.products.filter((element) => element.id !== data.id),
      data,
    ];
  },
  FETCH_DEFAULT_MEASUREMENTS(state, data) {
    state.defaultMeasurements = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
