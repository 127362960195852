/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";
//import Vue from "vue";

// initial state
const state = {

};

// getters
const getters = {
  getLocalStorageItems: (state) => {
    return state.localStorageItems;
  },
};

// actions
const actions = {
  //get all new registration
  async addLog({ dispatch }, payload) {
    //const name = payload.name;
    //const item = payload.item;
    //const datetime = payload.datetime;

    let logs = await dispatch("getLogs");

    console.log(logs, "Looogs");

    if (logs == null) {
      logs = [];
    }


    if (Array.isArray(logs)) {
      logs.push(payload);
    } else {
      logs = [logs, payload];
    }

    if (logs != null)
      dispatch("localstorage/setLocalStorageItem", { name: "CURRENT_LOGS", item: logs }, { root: true });
  },
  getLogs({ dispatch }) {
    const name = "CURRENT_LOGS";

    let item = dispatch("localstorage/getLocalStorageItem", { name: name }, { root: true });

    return item;
  },
  async sendLogs({ commit, dispatch }) {
    let logs = await dispatch("getLogs");
    const payload = {
      logs: logs
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`api/logger/sendlogs`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          dispatch("clearLogs");
          commit("ADD_PRODUCT_MEASUREMENT", res.data);
        })
        .catch((err) => {
          dispatch("clearLogs");
          reject(err);
        });
    });
  },
  clearLogs({ dispatch }) {
    const name = "CURRENT_LOGS";

    dispatch("localstorage/clearLocalStorageItem", { name: name }, { root: true });
  },
};

// mutations
const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
