import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueAxios from "vue-axios";
import axios from "axios";
import VueYoutube from "vue-youtube";
import moment from "moment";
import vuePlayer from "@algoz098/vue-player";
import VueSilentbox from "vue-silentbox";
import "leaflet/dist/leaflet.css";
import OneSignalVue from "onesignal-vue";

// Leaflet icon missing fix
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

//local moment
moment.locale("nl");

//import VueAuth from "@websanova/vue-auth";
import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
//import * as VueAuth from "@websanova/vue-auth";
import { DRIVERS, OPTIONS } from "./auth/authconfig";

// validation Configurations
import "./config/configuration";
import { baseURLProd, baseURLLocal } from "./config/configuration";

//Cropper
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

//Filters
import "./filters/filters";

// validation Configurations
import "./config/validationConfig";

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueAxios, axios);
Vue.component(vuePlayer);
Vue.component(Cropper);
Vue.use(VueSilentbox);
Vue.use(OneSignalVue);

//Change depending on used URL in project
if (process.env.NODE_ENV === "production") {
  Vue.axios.defaults.baseURL = baseURLProd;
} else {
  Vue.axios.defaults.baseURL = baseURLLocal;
}
Vue.axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// Vue.axios.defaults.headers.post["Content-type"] =
//   "application/x-www-form-urlencoded";
Vue.axios.defaults.headers.post["Accept"] = "application/json";
Vue.axios.defaults.headers.common["Authorization"] =
  `Bearer ` + localStorage[auth.tokenDefaultName];

Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    // http: Vue.http, // Vue Resource
    router: router,
  },
  drivers: DRIVERS,
  options: OPTIONS,
});

Vue.use(VueYoutube);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({
      appId: "dd613f0b-28a5-445b-8ce9-d0c6ab153fa5",
      allowLocalhostAsSecureOrigin: true,
    });
  },
}).$mount("#app");

//Cordova OneSignal logic
// Add to index.js or the first page that loads with your app.
document.addEventListener("deviceready", OneSignalInit, false);
function OneSignalInit() {
  // Uncomment to set OneSignal device logging to VERBOSE
  // window.plugins.OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  window.plugins.OneSignal.setAppId("dd613f0b-28a5-445b-8ce9-d0c6ab153fa5");
  window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
    console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
  });

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(
    accepted
  ) {
    console.log("User accepted notifications: " + accepted);
  });
}
