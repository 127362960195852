/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
//import axios from "axios";
import Vue from "vue";

// initial state
const state = {};

// getters
const getters = {
  user() {
    return Vue.auth.user();
  },

  impersonating() {
    return Vue.auth.impersonating();
  },
};

// actions
const actions = {
  //get all new registration
  fetch(data) {
    return Vue.auth.fetch(data);
  },

  refresh({ dispatch }, data) {
    //set current timestamp as local
    const payload = {
      name: "CURRENT_TIMESTAMP",
      item: Math.round(Date.now() / 1000),
    };
    dispatch("localstorage/setLocalStorageItem", payload, { root: true });

    return Vue.auth.refresh(data);
  },

  login(ctx, data) {
    data = data || {};

    return new Promise((resolve, reject) => {
      Vue.auth
        .login({
          url: "auth/login",
          data: data.body,
          remember: data.remember,
          staySignedIn: data.staySignedIn,
        })
        .then((res) => {
          if (data.remember) {
            Vue.auth.remember(
              JSON.stringify({
                name: ctx.getters.user.first_name,
              })
            );
          }

          Vue.router.push({
            name: ctx.getters.user.type + "-landing",
          });

          resolve(res);
        }, reject);
    });
  },

  register(ctx, data) {
    data = data || {};

    return new Promise((resolve, reject) => {
      Vue.auth
        .register({
          url: "auth/register",
          data: data.body,
          autoLogin: false,
        })
        .then(() => {
          if (data.autoLogin) {
            ctx.dispatch("login", data).then(resolve, reject);
          }
        }, reject);
    });
  },

  impersonate(ctx, data) {
    var props = this.getters["properties/data"];
    console.log(props, ctx);
    Vue.auth.impersonate({
      url: "auth/" + data.user.id + "/impersonate",
      redirect: "user-account",
    });
  },

  unimpersonate(ctx) {
    console.log(ctx);
    Vue.auth.unimpersonate({
      redirect: "admin-users",
    });
  },

  logout(ctx) {
    console.log(ctx);
    return Vue.auth.logout();
  },
};

// mutations
const mutations = {
  FETCH_PACKAGE(state, data) {
    state.packages = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
