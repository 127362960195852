/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  questions: [],
  evaluations: [],
  allEvaluations: []
};

// getters
const getters = {
  getQuestions: (state) => {
    return state.questions;
  },
  getAllEvaluations: (state) => {
    return state.allEvaluations;
  },
  getEvaluations: (state) => {
    return state.evaluations.sort((a, b) => (a.position > b.position ? 1 : -1));
  },
  getFoodExercises: (state) => {
    return state.questions.find((x) => x.type == 0);
  },
};

// actions
const actions = {
  //get all new registration
  fetchOwnQuestions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions")
        .then((res) => {
          commit("FETCH_QUESTIONS", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchQuestions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/" + payload.userId)
        .then((res) => {
          commit("FETCH_QUESTIONS", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions/user", payload)
        .then((res) => {
          commit("EDIT_QUESTION", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions", payload)
        .then((res) => {
          commit("CREATE_QUESTION", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createMovementAnalysesCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/movementanalyses/coach", payload)
        .then((res) => {
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/questions/" + payload.id + "/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_QUESTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/questions/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_QUESTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendFeedbackEvaluation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/questions/feedback/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("ADD_FEEDBACK_EVALUATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendFeedbackQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/questions/feedback/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_QUESTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchEvaluations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/eval/" + payload.userId)
        .then((res) => {
          commit("FETCH_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createEvaluation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions/eval", payload)
        .then((res) => {
          commit("CREATE_EVALUATION", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editEvaluation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/questions/eval/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_EVALUATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editEvaluationPosition({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/questions/eval/pos/" + payload.userId, payload.questionDTOs)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_EVALUATION_POSITION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchOwnEvaluations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/eval")
        .then((res) => {
          commit("FETCH_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveEvaluation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions/user/eval", payload)
        .then((res) => {
          commit("EDIT_EVALUATION", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  recreateEvaluations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/eval/new")
        .then((res) => {
          commit("FETCH_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachPastEvaluations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions/eval/old/" + payload.id, payload)
        .then((res) => {
          commit("FETCH_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPastEvaluations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/questions/eval/old", payload)
        .then((res) => {
          commit("FETCH_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //fetchAllPastEvaluations
  fetchAllPastEvaluations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/eval/submissions")
        .then((res) => {
          commit("FETCH_ALL_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachAllPastEvaluations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/questions/eval/submissions/" + payload.id)
        .then((res) => {
          commit("FETCH_ALL_EVALUATIONS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteEvaluation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/questions/" + payload.id + "/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_EVALUATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_QUESTIONS(state, data) {
    state.questions = data;
  },
  DELETE_QUESTION(state, data) {
    let index = state.questions.findIndex((item) => item.id === data.id);
    state.questions.splice(index, 1);
  },
  EDIT_QUESTION(state, data) {
    if (Array.isArray(data)) {
      state.questions = data;
    } else {
      state.questions = [
        ...state.questions.filter((element) => element.id !== data.id),
        data,
      ];
    }

    state.questions.sort(function (a, b) {
      return a.id - b.id;
    });
  },
  ADD_FEEDBACK_EVALUATION(state, data) {
    console.log(state.evaluations, data)
    if (Array.isArray(data)) {
      state.evaluations = data;
    } else {
      state.evaluations = [
        ...state.evaluations.filter((element) => element.id !== data.id),
        data,
      ];
    }

    state.questions.sort(function (a, b) {
      return a.id - b.id;
    });
  },
  CREATE_QUESTION(state, data) {
    state.questions.unshift(data);
  },
  //eval
  FETCH_EVALUATIONS(state, data) {
    state.evaluations = data;
  },
  FETCH_ALL_EVALUATIONS(state, data) {
    state.allEvaluations = data;
  },
  CREATE_EVALUATION(state, data) {
    state.evaluations.unshift(data);
    state.evaluations = state.evaluations.sort((a, b) => (a.position > b.position ? 1 : -1));
  },
  EDIT_EVALUATION(state, data) {
    if (Array.isArray(data)) {
      state.evaluations = data;
    } else {
      state.evaluations = [
        ...state.evaluations.filter((element) => element.id !== data.id),
        data,
      ];
    }

    state.evaluations.sort(function (a, b) {
      return a.id - b.id;
    });
  },
  DELETE_EVALUATION(state, data) {
    let index = state.evaluations.findIndex((item) => item.id === data.id);
    state.evaluations.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
