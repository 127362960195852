import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
import { omniaBaseTheme } from "../config/configuration";
Vue.use(Vuetify);

//base teambenji
const themeBenji = {
  primary: "#201E1F", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  secondary: "#399E5A",
  info: "#a0cbdf",
  success: "#21c1d6",
  accent: "#399E5A",
  default: "#563dea",
  complement: "#70949c",
};

//base omnia tracker
const themeOmnia = {
  primary: "#201E1F", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  secondary: "#399E5A",
  info: "#29B6F6",
  success: "#43A047",
  accent: "#D5FBFE",
  default: "#399E5A",
  complement: "#c1a59a",
};

let theme = omniaBaseTheme ? themeOmnia : themeBenji;

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
