/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  packages: [],
  packageOptions: [],
  currentPackage: {},
};

// getters
const getters = {
  getPackages: (state) => {
    return state.packages;
  },
  getCurrentPackage: (state) => {
    return state.currentPackage;
  },
  getPackageOptions: (state) => {
    return state.packageOptions;
  },
};

// actions
const actions = {
  //get all new registration
  fetchPackages({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/packages")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchOwnPackages({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/packages/own")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachPackages({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/packages/coach")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachPackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/packages/coach/" + payload.packageId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_CURRENT_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editPackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/packages/coach/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_CURRENT_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editPackageActivity({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/packages/update/activity/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("EDIT_CURRENT_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deletePackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/packages/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deletePackageOption({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/packages/option/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_PACKAGE_OPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPackageOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/packages/coach/options")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_PACKAGEOPTIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  payPackageOption({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Payments", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("PAY_PACKAGEOPTIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addPackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/packages", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_PACKAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addPackageOption({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/packages/option", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_PACKAGE_OPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editPackageOption({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/packages/option", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_PACKAGE_OPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_PACKAGE(state, data) {
    state.packages = data;
  },
  FETCH_CURRENT_PACKAGE(state, data) {
    state.currentPackage = data;
  },
  EDIT_CURRENT_PACKAGE(state, data) {
    state.currentPackage = data;
  },
  FETCH_PACKAGEOPTIONS(state, data) {
    state.packageOptions = data;
  },
  PAY_PACKAGEOPTIONS() {
    //do nothinh
  },
  ADD_PACKAGE(state, data) {
    state.packages.unshift(data);
  },
  ADD_PACKAGE_OPTION(state, data) {
    state.currentPackage.packageOptions = data;
  },
  EDIT_PACKAGE_OPTION(state, data) {
    state.currentPackage.packageOptions = data;
  },
  DELETE_PACKAGE(state, data) {
    let index = state.packages.findIndex((item) => item.id === data.id);
    state.packages.splice(index, 1);
  },
  DELETE_PACKAGE_OPTION(state, data) {
    let index = state.currentPackage.packageOptions.findIndex(
      (item) => item.id === data.id
    );
    state.currentPackage.packageOptions.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
