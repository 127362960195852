/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  subCoaches: [],
  branches: []
};

// getters
const getters = {
  getSubCoaches: (state) => {
    return state.subCoaches;
  },
  getBranches: (state) => {
    return state.branches;
  },
};

// actions
const actions = {
  //get all current coaches
  fetchSubCoaches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/subCoaches")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_SUB_COACHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createSubCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/subCoaches", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_SUB_COACH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateSubCoachUserList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/subCoaches", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_SUB_COACHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteSubCoach({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/subCoaches/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_SUB_COACH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //BRANCHES
  fetchCoachBranches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/subCoaches/branches")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_BRANCHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCoachBranch({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/subCoaches/branch/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_BRANCHE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createBranch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/subCoaches/branch", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_BRANCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editBranch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/subCoaches/branch/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_BRANCH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateBranchToSubCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/subCoaches/connectBranch", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_SUB_COACH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_SUB_COACHES(state, data) {
    state.subCoaches = data;
  },
  CREATE_SUB_COACH(state, data) {
    state.subCoaches.unshift(data);
  },
  DELETE_SUB_COACH(state, data) {
    let index = state.subCoaches.findIndex((item) => item.id === data.id);
    state.subCoaches.splice(index, 1);
  },
  UPDATE_SUB_COACH(state, data) {
    state.subCoaches = [
      ...state.subCoaches.filter((element) => element.id !== data.id),
      data,
    ];
  },
  //BRANCHES
  FETCH_BRANCHES(state, data) {
    state.branches = data;
  },
  DELETE_BRANCHE(state, data) {
    let index = state.branches.findIndex((item) => item.id === data.id);
    state.branches.splice(index, 1);
  },
  CREATE_BRANCH(state, data) {
    state.branches.unshift(data);
  },
  EDIT_BRANCH(state, data) {
    state.branches = [
      ...state.branches.filter((element) => element.id !== data.id),
      data,
    ];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
