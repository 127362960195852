/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
    emails: [],
};

// getters
const getters = {
    getEmails: (state) => {
        return state.emails;
    },
};

// actions
const actions = {
    fetchEmails({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/emails")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_EMAILS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    resendEmail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/emails/request/" + payload.id, payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("UPDATE_EMAIL_BY_COURSE_REQUEST", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    FETCH_EMAILS(state, data) {
        state.emails = data;
    },
    UPDATE_EMAIL_BY_COURSE_REQUEST(state, data) {
        state.emails = [...state.courseRequestEmails.map(item => item.id !== data.id ? item : { ...item, ...data })]
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
