/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  calculatedNutrition: {},
  userNutrition: {},
  userNutritionSettings: {},
  userNutritionAdvice: [],
  userNutritionAdviceGroups: [],
  userNutritionMeals: [],
  userNutritionHistory: []
};

// getters
const getters = {
  getCalculatedNutrition: (state) => {
    return state.calculatedNutrition;
  },
  getUserNutrition: (state) => {
    return state.userNutrition;
  },
  getUserNutritionSettings: (state) => {
    return state.userNutritionSettings;
  },
  getUserNutritionAdvice: (state) => {
    return state.userNutritionAdvice;
  },
  getUserNutritionAdviceGroups: (state) => {
    return state.userNutritionAdviceGroups;
  },
  getUserNutritionMeals: (state) => {
    return state.userNutritionMeals;
  },
  getUserNutritionMealsRegular: (state) => {
    return state.userNutritionMeals.filter(x => x.nutritionMealType == 0);
  },
  getUserNutritionMealsTraining: (state) => {
    return state.userNutritionMeals.filter(x => x.nutritionMealType == 1);
  },
  getUserNutritionMealsRest: (state) => {
    return state.userNutritionMeals.filter(x => x.nutritionMealType == 2);
  },
  getUserNutritionHistory: (state) => {
    return state.userNutritionHistory;
  },
};

// actions
const actions = {
  //get all new registration
  fetchCalculatedNutrition({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/usernutritions/calculation/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_CALCULATED_NUTRITION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNutrition({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoacheeNutrition({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNutritionSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userSettings/nutrition/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_SETTINGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoacheeNutritionSettings({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userSettings/nutrition")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_SETTINGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addUserNutrition({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/userNutritions", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_USER_NUTRITION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //ADVICE
  fetchUserNutritionAdvice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/advice/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_ADVICE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addUserNutritionAdvice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/userNutritions/advice", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_USER_NUTRITION_ADVICE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteUserNutritionAdvice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "api/userNutritions/advice/" + payload.id + "/" + payload.userId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_USER_NUTRITION_ADVICE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchNutritionAdvice({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/advice/")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_ADVICE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //GROUPS
  fetchNutritionAdviceGroups({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/adviceGroups/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_ADVICE_GROUPS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchNutritionAdviceByGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/userNutritions/advice/" + payload.groupId + "/" + payload.userId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_ADVICE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addUserNutritionAdviceGroups({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/userNutritions/adviceGroups", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_USER_NUTRITION_ADVICE_GROUPS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteUserNutritionAdviceGroups({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "api/userNutritions/adviceGroups/" + payload.id + "/" + payload.userId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("DELETE_USER_NUTRITION_ADVICE_GROUPS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  toggleActiveNutritionAdviceGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/userNutritions/toggleActive/" + payload.id, payload)
        .then((res) => {
          commit("FETCH_USER_NUTRITION_ADVICE_GROUPS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //MEALS
  fetchUserNutritionMeals({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/meals/" + payload.userId)
        .then((res) => {
          commit("FETCH_USER_NUTRITION_MEALS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchOwnUserNutritionMeals({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/meals/")
        .then((res) => {
          commit("FETCH_USER_NUTRITION_MEALS", res.data);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //HISTORY
  addUserNutritionHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/userNutritions/history", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_USER_NUTRITION_HISTORY", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNutritionHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/history", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_HISTORY", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNutritionHistoryCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/userNutritions/history/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NUTRITION_HISTORY", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_CALCULATED_NUTRITION(state, data) {
    state.calculatedNutrition = data;
  },
  FETCH_USER_NUTRITION(state, data) {
    state.userNutrition = data;
  },
  FETCH_USER_NUTRITION_SETTINGS(state, data) {
    state.userNutritionSettings = data;
  },
  ADD_USER_NUTRITION(state, data) {
    state.userNutrition = data;
  },
  FETCH_USER_NUTRITION_ADVICE(state, data) {
    state.userNutritionAdvice = data;
  },
  ADD_USER_NUTRITION_ADVICE(state, data) {
    state.userNutritionAdvice = data;
  },
  DELETE_USER_NUTRITION_ADVICE(state, data) {
    let index = state.userNutritionAdvice.findIndex(
      (item) => item.id === data.id
    );
    state.userNutritionAdvice.splice(index, 1);
  },
  //GROUPS
  FETCH_USER_NUTRITION_ADVICE_GROUPS(state, data) {
    state.userNutritionAdviceGroups = data;
  },
  ADD_USER_NUTRITION_ADVICE_GROUPS(state, data) {
    state.userNutritionAdviceGroups = data;
  },
  DELETE_USER_NUTRITION_ADVICE_GROUPS(state, data) {
    let index = state.userNutritionAdviceGroups.findIndex(
      (item) => item.id === data.id
    );
    state.userNutritionAdviceGroups.splice(index, 1);
  },
  //MEALS
  FETCH_USER_NUTRITION_MEALS(state, data) {
    state.userNutritionMeals = data;
  },
  //HISTORY
  ADD_USER_NUTRITION_HISTORY(state, data) {
    state.userNutritionHistory = data;
  },
  FETCH_USER_NUTRITION_HISTORY(state, data) {
    state.userNutritionHistory = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
