<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
    <!-- <v-btn @click="localStoreTraining()">set Local</v-btn
    ><v-btn @click="getLocalItems()">use Local</v-btn> -->
    <global-loader />
    <snack-bars />
  </v-app>
</template>

<script>
import GlobalLoader from "./components/commonComponents/GlobalLoader.vue";
import SnackBars from "./components/commonComponents/SnackBars.vue";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
export default {
  name: "App",
  data: () => ({
    snackbar: true,
  }),

  components: {
    GlobalLoader,
    SnackBars,
  },
  created() {
    //cordova events
    document.addEventListener("resume", this.onResume, false);

    //Apple fix
    document.addEventListener("active ", this.onResume, false);

    document.addEventListener("deviceready", this.onDeviceReady, false);

    //pauze
    document.addEventListener("pause", this.onPause, false);
  },
  mounted() {
    //set colors
    this.$store
      .dispatch("fetchCoachTheme")
      .then(() => {
        this.loading = false;
      })
      .catch(function () {});

    this.createLog("Initialize", "success");
  },
  computed: {
    ...mapGetters({
      training: "trainings/getCurrentFilledTraining",
    }),
  },
  methods: {
    createLog(name, item) {
      const payload = {
        name: name,
        item: item,
        datetime: this.getCurrentTimestamp(),
      };

      this.$store.dispatch("logger/addLog", payload);
    },
    forceRefresh() {
      let app = this;
      app.$store.dispatch("auth/refresh");
    },
    getPreviousTimestamp() {
      let app = this;
      const payload = {
        name: "CURRENT_TIMESTAMP",
      };
      return app.$store.dispatch("localstorage/getLocalStorageItem", payload);
    },
    getCurrentTimestamp() {
      return Math.round(Date.now() / 1000);
    },
    redirectIfNeeded() {
      let app = this;
      this.getPreviousTimestamp().then((values) => {
        const diff = this.getCurrentTimestamp() - values;
        if (diff >= 5400) {
          //if has been more than 1,5 hours
          this.createLog(
            "Call 'redirectIfNeeded' more then 5400",
            `${diff} difference`
          );
          //force to dashboard
          //app.navigateTo("Coachee");
          app.navigateOnLoaded("Coachee");
        } else {
          this.createLog(
            "Call 'redirectIfNeeded' less then 5400",
            `${diff} difference (wont redirect)`
          );
        }
      });
    },
    navigateOnLoaded: debounce(function (route) {
      let app = this;
      this.$nextTick(() => {
        this.createLog("Wait till DOM loaded", `${route} = route`);
        app.navigateTo(route);
      });
    }, 1500), // increase to ur needs
    onResume() {
      //handle the resume event
      //var string = device.platform;
      this.createLog("Call 'onResume' on device", `No real item value`);
      let app = this;
      app.$store.dispatch("auth/refresh").then(() => {
        this.$store.dispatch("auth/fetch").then(() => {
          setTimeout(() => {
            this.createLog(
              "Try Call 'redirectIfNeeded' after onResume",
              `No real item value`
            );

            this.createLog(
              "Collected onResume user = ",
              `${JSON.stringify(app.$auth.user())}`
            );
            app.redirectIfNeeded();
          }, 100);
        });
        /*app.$store.dispatch("addMessage", {
          message: "Is opnieuw geladen",
          bottom: true,
          top: false,
          right: true,
          color: "green",
          transition: "fab-transition",
          timeout: 3000,
        });*/
      });
    },
    onPause() {
      //handle the pause event
      this.createLog("Call 'onPause'", `No real item value`);
      //store training
      this.localStoreTraining();
    },
    onDeviceReady() {
      //handle the device ready event
      //console.log(device.cordova);
      this.createLog("Call 'onDeviceReady' on device", `No real item value`);
      let app = this;
      this.$store.dispatch("auth/refresh").then((values) => {
        this.createLog("Call 'auth/refresh' on device", JSON.stringify(values));
        this.$store.dispatch("auth/fetch").then((values2) => {
          this.createLog(
            "Call 'auth/fetch' on device",
            JSON.stringify(values2)
          );
          this.createLog(
            "Collected user info is = ",
            JSON.stringify(app.$auth.user())
          );
          if (values.status == 200) {
            setTimeout(() => {
              this.createLog(
                "Success refresh call status 200",
                `Should be logged in now + navigate to dashboard`
              );
              //app.navigateTo("home");
              app.navigateOnLoaded("Coachee");

              //set local items
              this.getLocalItems();
            }, 500);
          } else {
            app.$store.dispatch("addMessage", {
              message: "Sessie ongeldig, log opnieuw in.",
              bottom: true,
              top: false,
              right: true,
              color: "warning",
              transition: "fab-transition",
              timeout: 3000,
            });
          }
        });
      });
    },
    navigateTo(route) {
      this.$router.push({ name: route });
    },
    localStoreTraining() {
      let currentTraining = this.training;

      // currentTraining.filledExercises = currentTraining.filledExercises.filter(
      //   (element) => {
      //     return element !== null;
      //   }
      // );
      // currentTraining.intensity = currentTraining.intensity.filter(
      //   (element) => {
      //     return element !== null;
      //   }
      // );

      const payload = {
        name: "CURRENT_TRAINING",
        item: currentTraining,
      };
      this.$store.dispatch("localstorage/setLocalStorageItem", payload);
    },
    localStore(key, value) {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    getLocal(key) {
      return window.localStorage.getItem(key);
    },
    getLocalItems() {
      //get training
      const currentTraining = this.training;
      let local = JSON.parse(this.getLocal("CURRENT_TRAINING"));

      local.intensity = local.intensity.filter((element) => {
        return element !== null;
      });

      if (this.isEmptyObject(currentTraining))
        this.$store.dispatch("trainings/setCurrentFilledTraining", local);
    },
    isEmptyObject(obj) {
      return (
        obj && // 👈 null and undefined check
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
  },
};
</script>
