/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  movementAnalyse: {},
};

// getters
const getters = {
  getMovementAnalyse: (state) => {
    return state.movementAnalyse;
  },
};

// actions
const actions = {
  //get all new registration
  fetchMovementAnalyse({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/movementanalyses")
        .then((res) => {
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchMovementAnalyseCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/movementanalyses/coach/" + payload.userId)
        .then((res) => {
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
          commit("FETCH_MOVEMENT_ANALYSE", {});
        });
    });
  },
  createMovementAnalyses({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/movementanalyses", payload)
        .then((res) => {
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createMovementAnalysesCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/movementanalyses/coach", payload)
        .then((res) => {
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
          setTimeout(() => resolve(res.data), 500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteMovementAnalysesExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/movementanalyses/" + payload.id + "/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_MOVEMENT_EXERCISE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  toggleMovementAnalysesExercise({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/movementanalyses/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_MOVEMENT_ANALYSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_MOVEMENT_ANALYSE(state, data) {
    state.movementAnalyse = data;
  },
  DELETE_MOVEMENT_EXERCISE(state, data) {
    let index = state.movementAnalyse.movementAnalyseExercises.findIndex(
      (item) => item.id === data.id
    );
    state.movementAnalyse.movementAnalyseExercises.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
