const contentRules = {
  allowedFileTypes: [
    ".png",
    ".jpeg",
    ".jpg",
    ".gif",
    ".wma",
    ".mp4",
    ".avi",
    ".mov",
  ],
  allowedProductFileTypes: [
    ".png",
    ".jpeg",
    ".jpg",
  ],
  maxProductByteSize: "2000000",
  maxByteSize: "100000000",
};

export default contentRules;
