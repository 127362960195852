import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/coachee",
      name: "coacheeHome",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        auth: {
          roles: ["Coachee"],
          redirect: { path: "/" },
          forbiddenRedirect: { name: "home" },
        },
      },
      children: [
        //Coachee specific routes
        {
          name: "ZeroReadingStart",
          path: "zero-reading/start",
          component: () => import("@/views/apps/zero-reading/ZeroReadingStart"),
        },
        {
          name: "ZeroReadingBase",
          path: "zero-reading/go",
          component: () => import("@/views/apps/zero-reading/ZeroReading"),
        },
        {
          name: "MovementAnalyseStart",
          path: "beweeg-analyse/start",
          component: () =>
            import("@/views/apps/movement-analyse/MovementAnalyseStart"),
        },
        {
          name: "MovementAnalyseBase",
          path: "beweeg-analyse/main",
          component: () =>
            import("@/views/apps/movement-analyse/MovementAnalyse"),
        },
        {
          name: "CoacheePackage",
          path: "pakket",
          component: () => import("@/views/apps/subscription/Subscription"),
        },
        {
          name: "ZeroReadingView",
          path: "zero-reading/view",
          component: () =>
            import(
              "@/views/apps/zero-reading/zero-reading-view/ZeroReadingViewBase"
            ),
          children: [
            {
              name: "ZeroReadingViewAlgemeen",
              path: "Algemeen",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/General.vue"
                ),
            },
            {
              path: "Lengte",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Length.vue"
                ),
            },
            {
              path: "Voeding",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Food.vue"
                ),
            },
            {
              path: "Sport",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Sport.vue"
                ),
            },
            {
              path: "Blessures",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Injuries.vue"
                ),
            },
            {
              path: "leefstijl",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Lifestyle.vue"
                ),
            },
            {
              path: "doelen",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/tabs-view/Goals.vue"
                ),
            },
          ],
        },
        {
          name: "BodyComposition",
          path: "lichaamssamenstelling",
          component: () =>
            import("@/views/apps/body-composition/UserBodyComposition"),
          children: [
            {
              path: "huidige",
              component: () =>
                import(
                  "@/views/apps/body-composition/body-composition-tabs/Current"
                ),
            },
            {
              name: "BodyCompositionProgressionCoachee",
              path: "progressie",
              component: () =>
                import(
                  "@/views/apps/body-composition/body-composition-tabs/Progression"
                ),
            },
            {
              name: "BodyCompositionUpdateValuesCoachee",
              path: "aanpassen",
              component: () =>
                import(
                  "@/views/apps/body-composition/body-composition-tabs/UpdateValues"
                ),
            },
            {
              name: "BodyCompositionUploadCoachee",
              path: "uploaden",
              component: () =>
                import(
                  "@/views/apps/body-composition/body-composition-tabs/Upload"
                ),
            },
          ],
        },
        {
          name: "UserFoodExercises",
          path: "voedingsopdrachten",
          component: () => import("@/views/apps/food-exercises/FoodExercises"),
        },
        {
          name: "UserEvaluation",
          path: "evaluatie",
          component: () => import("@/views/apps/evaluation/Evaluation"),
        },
        {
          name: "ProgressPhotos",
          path: "voortgangsfotos",
          component: () => import("@/views/apps/body-composition/progress/ProgressPhotos"),
        },
        {
          name: "UserNutrition",
          path: "voedingswaardes",
          component: () => import("@/views/apps/nutrition/NutritionValues"),
        },
        {
          name: "UserNutritionAdvice",
          path: "voedingsadvies",
          component: () => import("@/views/apps/nutrition/NutritionAdvice"),
        },
        {
          name: "CoacheeTraining",
          path: "training",
          component: () => import("@/views/apps/training/CoacheeTraining"),
        },
        {
          name: "CoacheeSchedule",
          path: "schedule",
          component: () => import("@/views/apps/schedule/CoacheeSchedule"),
        },
        {
          name: "CoacheeExerciseList",
          path: "oefeningen",
          component: () => import("@/views/apps/exercises-grid/ExercisesGrid"),
          props: { coachee: true },
        },
        {
          name: "CoacheeExerciseView",
          path: "oefening/:id",
          props: true,
          component: () => import("@/views/apps/exercises-grid/ExerciseView"),
          children: [
            {
              path: "uitleg",
              component: () =>
                import(
                  "@/views/apps/exercises-grid/exercise-tabs/ExerciseVideo"
                ),
            },
            {
              path: "progressie",
              component: () =>
                import(
                  "@/views/apps/exercises-grid/exercise-tabs/ExerciseProgress"
                ),
            },
            {
              path: "pr",
              component: () =>
                import("@/views/apps/exercises-grid/exercise-tabs/ExercisePR"),
            },
            {
              path: "upload",
              component: () =>
                import(
                  "@/views/apps/exercises-grid/exercise-tabs/ExerciseUpload"
                ),
            },
          ],
        },
        {
          name: "CoacheeCalendar",
          path: "agenda",
          component: () =>
            import("@/views/apps/calendar/coachee-calendar/CoacheeCalendar"),
          meta: {
            auth: {
              roles: ["Coachee"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
      ],
    },
    {
      path: "/",
      name: "home",
      // redirect:
      //   { name: "Coachee" }, //Breakd developer account

      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        auth: true,
      },
      children: [
        // Application/ pages
        {
          name: "Exercises",
          path: "users/exercise",
          component: () => import("@/views/apps/exercises-grid/ExercisesGrid"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Trainings",
          path: "users/training",
          component: () => import("@/views/apps/contact-list/ContactList"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Registrations",
          path: "users/registrations",
          component: () =>
            import("@/views/apps/registration-list/RegistrationList"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Subcoaches",
          path: "users/subcoaches",
          component: () =>
            import("@/views/apps/subcoaches-list/SubCoachesList"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Members",
          path: "users/members",
          component: () => import("@/views/apps/user-list/UserList"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "MessagesCoach",
          path: "apps/message/:userId",
          props: true,
          component: () => import("@/views/apps/chat/Chat"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Messages",
          path: "apps/message",
          component: () => import("@/views/apps/chat/Chat"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "SubCoach", "Administrator"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Calendar",
          path: "apps/c1/agenda",
          component: () =>
            import("@/views/apps/calendar/coach-calendar/CoachCalendar"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "FoodProducts",
          path: "voeding/producten",
          component: () => import("@/views/apps/food/products/ProductList"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "ProductDetail",
          path: "voeding/product/inzage",
          props: true,
          component: () => import("@/views/apps/food/products/ProductDetail"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "ProductEdit",
          path: "voeding/product/aanpassen",
          props: true,
          component: () => import("@/views/apps/food/products/ProductEdit"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "FoodSchedule",
          path: "voeding/schema",
          component: () =>
            import("@/views/apps/food/schedule/FoodScheduleBase"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Account",
          path: "apps/profile",
          component: () => import("@/views/apps/profile/UserProfile.vue"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "PackageSettings",
          path: "apps/pakketten",
          component: () =>
            import("@/views/apps/packages-settings/Packages.vue"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "GeneralSettings",
          path: "apps/instellingen",
          component: () => import("@/views/apps/settings/Settings.vue"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
          children: [
            // Application/ pages
            {
              name: "SettingsViews",
              path: "aanzichten",
              component: () =>
                import(
                  "@/views/apps/settings/settings-detail/SettingsViews.vue"
                ),
              meta: {
                auth: {
                  roles: ["Coach"],
                  redirect: { path: "/" },
                  forbiddenRedirect: { name: "home" },
                },
              },
            },
            {
              name: "SettingsColours",
              path: "kleuren",
              component: () =>
                import(
                  "@/views/apps/settings/settings-detail/SettingsColours.vue"
                ),
              meta: {
                auth: {
                  roles: ["Coach"],
                  redirect: { path: "/" },
                  forbiddenRedirect: { name: "home" },
                },
              },
            },
            {
              name: "SettingsBranches",
              path: "filialen",
              component: () =>
                import(
                  "@/views/apps/settings/settings-detail/SettingsBranches.vue"
                ),
              meta: {
                auth: {
                  roles: ["Coach"],
                  redirect: { path: "/" },
                  forbiddenRedirect: { name: "home" },
                },
              },
            },
            {
              name: "SettingsSocials",
              path: "socials",
              component: () =>
                import(
                  "@/views/apps/settings/settings-detail/SettingsSocials.vue"
                ),
              meta: {
                auth: {
                  roles: ["Coach"],
                  redirect: { path: "/" },
                  forbiddenRedirect: { name: "home" },
                },
              },
            },
            {
              name: "SettingsBusiness",
              path: "bedrijf",
              component: () =>
                import(
                  "@/views/apps/settings/settings-detail/SettingsBusiness.vue"
                ),
              meta: {
                auth: {
                  roles: ["Coach"],
                  redirect: { path: "/" },
                  forbiddenRedirect: { name: "home" },
                },
              },
            },
          ],
        },
        {
          name: "PackageView",
          path: "apps/pakketten/:id",
          props: true,
          component: () =>
            import("@/views/apps/packages-settings/PackageView.vue"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          name: "Uploads",
          path: "apps/uploads",
          component: () => import("@/views/apps/uploads-list/Uploads.vue"),
          meta: {
            auth: {
              roles: ["Coach", "Coachee", "Developer", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          path: "subcoach/:id",
          name: "Subcoach",
          props: true,
          component: () => import("@/views/apps/subcoach/Subcoach.vue"),
          meta: {
            auth: {
              roles: ["Coach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
          children: [
            {
              name: "SubcoachOverview",
              path: "algemeen",
              component: () =>
                import("@/views/apps/subcoach/subcoach-tabs/overview/Overview"),
            },
            {
              name: "SubcoachCoachees",
              path: "deelnemers",
              component: () =>
                import("@/views/apps/subcoach/subcoach-tabs/coachees/Coachees"),
            },
          ],
        },
        {
          path: "/member/:id",
          name: "Member",
          props: true,
          component: () => import("@/views/apps/member/Member.vue"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
          children: [
            {
              name: "MemberTraining",
              path: "training",
              component: () =>
                import("@/views/apps/member/training/TrainingGrid"),
            },
            {
              name: "MemberOverview",
              path: "overview",
              component: () => import("@/views/apps/member/overview/Overview"),
            },
            {
              name: "MemberAddTraining",
              path: "toevoegen",
              component: () =>
                import("@/views/apps/member/training/add-training/AddTraining"),
            },
            {
              name: "MemberUpdateTraining",
              path: "aanpassen/:trainingId",
              props: true,
              component: () =>
                import(
                  "@/views/apps/member/training/update-training/UpdateTraining"
                ),
            },
            {
              name: "MemberViewTraining",
              path: "inzien/:trainingId",
              props: true,
              component: () =>
                import(
                  "@/views/apps/member/training/view-training/ViewTraining"
                ),
            },
            {
              name: "MemberViewExerciseProgression",
              path: "inzien/oefening/:exerciseId",
              props: true,
              component: () =>
                import(
                  "@/views/apps/member/training/view-training/view-exercise-progress/ViewExerciseProgress"
                ),
            },
            {
              name: "UploadsCoachee",
              path: "uploads",
              component: () => import("@/views/apps/member/uploads/Uploads"),
            },
            {
              name: "MemberBody",
              path: "lichaamsbouw",
              props: true,
              component: () =>
                import("@/views/apps/member/bodycomposition/BodyComposition"),
            },
            {
              name: "TrainingHistory2",
              path: "agenda/:date",
              component: () =>
                import("@/views/apps/member/history/TrainingHistoryBase"),
            },
            {
              name: "TrainingHistory1",
              path: "agenda",
              component: () =>
                import("@/views/apps/member/history/TrainingHistoryBase"),
            },
            {
              name: "MemberZeroReading",
              path: "nulmeting",
              component: () =>
                import(
                  "@/views/apps/zero-reading/zero-reading-view/ZeroReadingViewBaseCoach"
                ),
              children: [
                {
                  name: "ZeroReadingViewAlgemeen2",
                  path: "Algemeen",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/General.vue"
                    ),
                },
                {
                  path: "Lengte",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Length.vue"
                    ),
                },
                {
                  path: "Voeding",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Food.vue"
                    ),
                },
                {
                  path: "Sport",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Sport.vue"
                    ),
                },
                {
                  path: "Blessures",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Injuries.vue"
                    ),
                },
                {
                  path: "leefstijl",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Lifestyle.vue"
                    ),
                },
                {
                  path: "doelen",
                  component: () =>
                    import(
                      "@/views/apps/zero-reading/zero-reading-view/tabs-view/Goals.vue"
                    ),
                },
              ],
            },
            {
              name: "Subscription",
              path: "pakket",
              component: () =>
                import("@/views/apps/member/subscription/Subscription"),
            },
            {
              name: "Progression",
              path: "progressie",
              component: () =>
                import("@/views/apps/member/progression/Progression"),
            },
            {
              name: "Nutrition",
              path: "voeding",
              component: () =>
                import("@/views/apps/member/nutrition/Nutrition"),
            },
            {
              name: "MovementAnalyse",
              path: "beweeganalyse",
              component: () =>
                import("@/views/apps/member/movement-analyse/MovementAnalyse"),
            },
            {
              name: "FoodExercises",
              path: "voedingsopdrachten",
              component: () =>
                import("@/views/apps/member/food-exercises/FoodExercises"),
            },
            {
              name: "Evaluation",
              path: "evaluatie",
              component: () =>
                import("@/views/apps/member/evaluation/Evaluation"),
            },
            {
              name: "CoacheeSettings",
              path: "instellingen",
              component: () =>
                import("@/views/apps/member/overview/MemberSettings2.vue"),
            },
          ],
        },
        {
          name: "AddTraining",
          path: "/trainingtoevoegen",
          component: () =>
            import("@/views/apps/member/training/add-training/AddTraining"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              redirect: { path: "/" },
              forbiddenRedirect: { name: "home" },
            },
          },
        },
        {
          path: "/admin",
          name: "AdminMain",
          component: () => import("@/layouts/admin-layout/AdminLayout"),
          meta: {
            auth: {
              roles: ["Administrator"],
              forbiddenRedirect: { name: "home" },
            },
          },
          children: [
            {
              path: "coaches",
              name: "AdminCoachesView",
              component: () => import("@/views/admin/coaches/Coaches.vue"),
            },
            {
              path: "coach/:id",
              name: "Coach",
              props: true,
              component: () => import("@/views/admin/coach/Coach.vue"),
              children: [
                {
                  name: "CoachReport",
                  path: "rapportage",
                  component: () =>
                    import("@/views/admin/coach/coach-tabs/report/Report"),
                },
                {
                  name: "CoachOverview",
                  path: "algemeen",
                  component: () =>
                    import("@/views/admin/coach/coach-tabs/overview/Overview"),
                },
                {
                  name: "CoachCoachees",
                  path: "deelnemers",
                  component: () =>
                    import("@/views/admin/coach/coach-tabs/coachees/Coachees"),
                },
                {
                  name: "CoachSettings",
                  path: "instellingen",
                  component: () =>
                    import(
                      "@/views/admin/coach/coach-tabs/overview/CoachSettings.vue"
                    ),
                },
              ],
            },
          ],
        },

        {
          path: "/developer",
          name: "DeveloperMain",
          component: () => import("@/layouts/admin-layout/AdminLayout"),
          meta: {
            auth: {
              roles: ["Developer"],
              forbiddenRedirect: { name: "home" },
            },
          },
          children: [
            {
              path: "emails",
              name: "DeveloperEmails",
              component: () => import("@/views/developer/Emails.vue"),
            },
          ],
        },

        // Components (Niet echt pagina's)
        // {
        //   name: "Alerts",
        //   path: "components/alerts",
        //   component: () => import("@/views/components/Alerts"),
        // },
        // {
        //   name: "Avatar",
        //   path: "components/avatar",
        //   component: () => import("@/views/components/Avatar"),
        // },
        // {
        //   name: "Badge",
        //   path: "components/badge",
        //   component: () => import("@/views/components/Badge"),
        // },
        // {
        //   name: "Banner",
        //   path: "components/banner",
        //   component: () => import("@/views/components/Banner"),
        // },
        // {
        //   name: "Appbar",
        //   path: "components/appbar",
        //   component: () => import("@/views/components/Appbar"),
        // },
        // {
        //   name: "Toolbar",
        //   path: "components/toolbar",
        //   component: () => import("@/views/components/Toolbar"),
        // },
        // {
        //   name: "Systembar",
        //   path: "components/systembar",
        //   component: () => import("@/views/components/Systembar"),
        // },
        // {
        //   name: "BottomNavigation",
        //   path: "components/bottomnavigation",
        //   component: () => import("@/views/components/BottomNavigation"),
        // },
        // {
        //   name: "Bottomsheet",
        //   path: "components/bottomsheet",
        //   component: () => import("@/views/components/Bottomsheet"),
        // },
        // {
        //   name: "Breadcrumb",
        //   path: "components/breadcrumb",
        //   component: () => import("@/views/components/Breadcrumb"),
        // },
        // {
        //   name: "Buttons",
        //   path: "components/buttons",
        //   component: () => import("@/views/components/Buttons"),
        // },
        // {
        //   name: "Fab",
        //   path: "components/fab",
        //   component: () => import("@/views/components/Fab"),
        // },
        // {
        //   name: "Cards",
        //   path: "components/cards",
        //   component: () => import("@/views/components/Cards"),
        // },
        // {
        //   name: "Carousel",
        //   path: "components/carousel",
        //   component: () => import("@/views/components/Carousel"),
        // },
        // {
        //   name: "Chips",
        //   path: "components/chips",
        //   component: () => import("@/views/components/Chips"),
        // },
        // {
        //   name: "Dialogs",
        //   path: "components/dialogs",
        //   component: () => import("@/views/components/Dialogs"),
        // },
        // {
        //   name: "Dividers",
        //   path: "components/dividers",
        //   component: () => import("@/views/components/Dividers"),
        // },
        // {
        //   name: "Expansion Panel",
        //   path: "components/expansionpanel",
        //   component: () => import("@/views/components/ExpansionPanel"),
        // },
        // {
        //   name: "Footer",
        //   path: "components/footer",
        //   component: () => import("@/views/components/Footer"),
        // },
        // {
        //   name: "Sheet",
        //   path: "components/sheet",
        //   component: () => import("@/views/components/Sheet"),
        // },
        // {
        //   name: "Overflow Buttons",
        //   path: "components/overflow-buttons",
        //   component: () => import("@/views/components/OverflowButtons"),
        // },
        // {
        //   name: "Grids",
        //   path: "components/grids",
        //   component: () => import("@/views/components/Grids"),
        // },
        // {
        //   name: "Button Groups",
        //   path: "components/button-group",
        //   component: () => import("@/views/components/ButtonGroups"),
        // },
        // {
        //   name: "Chip Groups",
        //   path: "components/chip-group",
        //   component: () => import("@/views/components/ChipGroups"),
        // },
        // {
        //   name: "Item Groups",
        //   path: "components/item-group",
        //   component: () => import("@/views/components/ItemGroups"),
        // },
        // {
        //   name: "List Item Groups",
        //   path: "components/listitem-group",
        //   component: () => import("@/views/components/ListItemGroups"),
        // },
        // {
        //   name: "Slide Groups",
        //   path: "components/slide-group",
        //   component: () => import("@/views/components/SlideGroups"),
        // },
        // {
        //   name: "Windows",
        //   path: "components/windows",
        //   component: () => import("@/views/components/Windows"),
        // },
        // {
        //   name: "Hover",
        //   path: "components/hover",
        //   component: () => import("@/views/components/Hover"),
        // },
        // {
        //   name: "Icons",
        //   path: "components/icons",
        //   component: () => import("@/views/components/Icons"),
        // },
        // {
        //   name: "Images",
        //   path: "components/images",
        //   component: () => import("@/views/components/Images"),
        // },
        // {
        //   name: "Lists",
        //   path: "components/lists",
        //   component: () => import("@/views/components/Lists"),
        // },
        // {
        //   name: "Menus",
        //   path: "components/menus",
        //   component: () => import("@/views/components/Menus"),
        // },
        // {
        //   name: "Navigation Drawers",
        //   path: "components/navigation-drawers",
        //   component: () => import("@/views/components/NavigationDrawers"),
        // },
        // {
        //   name: "Overlay",
        //   path: "components/overlay",
        //   component: () => import("@/views/components/Overlay"),
        // },
        // {
        //   name: "Pagination",
        //   path: "components/pagination",
        //   component: () => import("@/views/components/Pagination"),
        // },
        // {
        //   name: "Parallex",
        //   path: "components/parallex",
        //   component: () => import("@/views/components/Parallex"),
        // },
        // {
        //   name: "Color Pickers",
        //   path: "components/color-pickers",
        //   component: () => import("@/views/components/ColorPickers"),
        // },
        // {
        //   name: "Date Pickers",
        //   path: "components/date-pickers",
        //   component: () => import("@/views/components/DatePickers"),
        // },
        // {
        //   name: "Time Pickers",
        //   path: "components/time-pickers",
        //   component: () => import("@/views/components/TimePickers"),
        // },
        // {
        //   name: "Progress Circular",
        //   path: "components/progress-circular",
        //   component: () => import("@/views/components/ProgressCircular"),
        // },
        // {
        //   name: "Progress Linear",
        //   path: "components/progress-linear",
        //   component: () => import("@/views/components/ProgressLinear"),
        // },
        // {
        //   name: "Rating",
        //   path: "components/rating",
        //   component: () => import("@/views/components/Rating"),
        // },
        // {
        //   name: "SkeletonLoaders",
        //   path: "components/skeleton-loaders",
        //   component: () => import("@/views/components/SkeletonLoaders"),
        // },
        // {
        //   name: "Snackbar",
        //   path: "components/snackbar",
        //   component: () => import("@/views/components/Snackbar"),
        // },
        // {
        //   name: "Sparkline",
        //   path: "components/sparkline",
        //   component: () => import("@/views/components/Sparkline"),
        // },
        // {
        //   name: "Steppers",
        //   path: "components/steppers",
        //   component: () => import("@/views/components/Steppers"),
        // },
        // {
        //   name: "Subheaders",
        //   path: "components/subheaders",
        //   component: () => import("@/views/components/Subheaders"),
        // },
        // {
        //   name: "Tabs",
        //   path: "components/tabs",
        //   component: () => import("@/views/components/Tabs"),
        // },
        // {
        //   name: "Timeline",
        //   path: "components/timeline",
        //   component: () => import("@/views/components/Timeline"),
        // },
        // {
        //   name: "Tooltips",
        //   path: "components/tooltips",
        //   component: () => import("@/views/components/Tooltips"),
        // },
        // {
        //   name: "Treeview",
        //   path: "components/treeview",
        //   component: () => import("@/views/components/Treeview"),
        // },
        // {
        //   name: "Colors",
        //   path: "style-animation/colors",
        //   component: () => import("@/views/style-animation/Colors"),
        // },
        // {
        //   name: "Content",
        //   path: "style-animation/content",
        //   component: () => import("@/views/style-animation/Content"),
        // },
        // {
        //   name: "Display",
        //   path: "style-animation/display",
        //   component: () => import("@/views/style-animation/Display"),
        // },
        // {
        //   name: "Elevation",
        //   path: "style-animation/elevation",
        //   component: () => import("@/views/style-animation/Elevation"),
        // },
        // {
        //   name: "Flex",
        //   path: "style-animation/flex",
        //   component: () => import("@/views/style-animation/Flex"),
        // },
        // {
        //   name: "Float",
        //   path: "style-animation/float",
        //   component: () => import("@/views/style-animation/Float"),
        // },
        // {
        //   name: "Spacing",
        //   path: "style-animation/spacing",
        //   component: () => import("@/views/style-animation/Spacing"),
        // },
        // {
        //   name: "Text",
        //   path: "style-animation/text",
        //   component: () => import("@/views/style-animation/Text"),
        // },
        // {
        //   name: "Typography",
        //   path: "style-animation/typography",
        //   component: () => import("@/views/style-animation/Typography"),
        // },
        // {
        //   name: "Transitions",
        //   path: "style-animation/transitions",
        //   component: () => import("@/views/style-animation/Transitions"),
        // },
        // {
        //   name: "ProgrammaticScrolling",
        //   path: "style-animation/scroll",
        //   component: () => import("@/views/style-animation/Scroll"),
        // },
        // {
        //   name: "ApexCharts",
        //   path: "charts/apexcharts",
        //   component: () => import("@/views/charts/apexcharts/ApexCharts"),
        // },
        {
          name: "Analytical",
          path: "dashboards/analytical",
          component: () => import("@/views/dashboards/analytical/Analytical"),
        },
        {
          name: "Coachee",
          path: "dashboards/coachee",
          component: () => import("@/views/dashboards/coachee/CoacheeHomepage"),
          meta: {
            auth: {
              roles: ["Coachee"],
              forbiddenRedirect: { name: "CoachDashboard" },
            },
          },
        },
        {
          name: "CoachDashboard",
          path: "dashboards/coach",
          component: () => import("@/views/dashboards/coach/CoachHomepage"),
          meta: {
            auth: {
              roles: ["Coach", "SubCoach"],
              //forbiddenRedirect: { name: "Coachee" },
            },
          },
        },
        {
          name: "AdminDashboard",
          path: "dashboards/administrator",
          component: () => import("@/views/dashboards/admin/AdminDashboard"),
          meta: {
            auth: {
              roles: ["Administrator"],
              forbiddenRedirect: { name: "Coachee" },
            },
          },
        },
        {
          name: "Classic",
          path: "dashboards/classic",
          component: () => import("@/views/dashboards/classic/Classic"),
        },
        {
          name: "Demographical",
          path: "dashboards/demographical",
          component: () =>
            import("@/views/dashboards/demographical/Demographical"),
        },
        {
          name: "Minimal",
          path: "dashboards/minimal",
          component: () => import("@/views/dashboards/minimal/Minimal"),
        },
        {
          name: "Overview",
          path: "dashboards/overview",
          component: () => import("@/views/dashboards/overview/Overview"),
        },
        {
          name: "Ecommerce",
          path: "dashboards/ecommerce",
          component: () => import("@/views/dashboards/ecommerce/Ecommerce"),
        },
        // {
        //   name: "FullCalendar",
        //   path: "apps/fullcalendar",
        //   component: () => import("@/views/apps/fullcalendar/FullCalendar"),
        // },
        // {
        //   name: "Ecommerce-shop",
        //   path: "apps/shop",
        //   component: () => import("@/views/apps/ecommerce/Listing"),
        // },
        // {
        //   name: "Contact",
        //   path: "apps/contact",
        //   component: () => import("@/views/apps/contact/Contact"),
        // },
        // {
        //   name: "ContactList",
        //   path: "apps/contact-list",
        //   component: () => import("@/views/apps/contact-list/ContactList"),
        // },
        // {
        //   name: "ContactGrid",
        //   path: "apps/contact-grid",
        //   component: () => import("@/views/apps/contact-grid/ContactGrid"),
        // },
        // {
        //   name: "Email",
        //   path: "apps/email/inbox",
        //   component: () => import("@/views/apps/email/Email"),
        //   children: [
        //     {
        //       path: "/apps/email/:mailType",
        //       name: "MailIndex",
        //       component: () => import("@/views/apps/email/EmailList.vue"),
        //     },
        //     {
        //       path: "/apps/email/:mailType/0/:uuid",
        //       name: "MailDetail1",
        //       component: () => import(`@/views/apps/email/EmailDetail.vue`),
        //     },
        //     {
        //       path: "/apps/email/0/:mailTag",
        //       name: "MailTag",
        //       component: () => import("@/views/apps/email/EmailList.vue"),
        //     },
        //     {
        //       path: "/apps/email/0/:mailTag/0/:uuid",
        //       name: "MailDetail",
        //       component: () => import(`@/views/apps/email/EmailDetail.vue`),
        //     },
        //   ],
        // },
        // {
        //   name: "Todo",
        //   path: "apps/todo",
        //   component: () => import("@/views/apps/todo/Todo"),
        // },
        // {
        //   name: "FEAutocompletes",
        //   path: "form-elements/FEautocompletes",
        //   component: () => import("@/views/form-elements/FEAutocompletes"),
        // },
        // {
        //   name: "FECombobox",
        //   path: "form-elements/FECombobox",
        //   component: () => import("@/views/form-elements/FECombobox"),
        // },
        // {
        //   name: "FEFileInputs",
        //   path: "form-elements/FEFileInputs",
        //   component: () => import("@/views/form-elements/FEFileInputs"),
        // },
        // {
        //   name: "FEInputs",
        //   path: "form-elements/FEInputs",
        //   component: () => import("@/views/form-elements/FEInputs"),
        // },
        // {
        //   name: "FEOverflowButtons",
        //   path: "form-elements/FEOverflowButtons",
        //   component: () => import("@/views/form-elements/FEOverflowButtons"),
        // },
        // {
        //   name: "FESelectionControls",
        //   path: "form-elements/FESelectionControls",
        //   component: () => import("@/views/form-elements/FESelectionControls"),
        // },
        // {
        //   name: "FESelects",
        //   path: "form-elements/FESelects",
        //   component: () => import("@/views/form-elements/FESelects"),
        // },
        // {
        //   name: "FESliders",
        //   path: "form-elements/FESliders",
        //   component: () => import("@/views/form-elements/FESliders"),
        // },
        // {
        //   name: "FETextareas",
        //   path: "form-elements/FETextareas",
        //   component: () => import("@/views/form-elements/FETextareas"),
        // },
        // {
        //   name: "FETextfields",
        //   path: "form-elements/FETextfields",
        //   component: () => import("@/views/form-elements/FETextfields"),
        // },
        // {
        //   name: "FLFormAction",
        //   path: "form-layouts/FLFormAction",
        //   component: () =>
        //     import("@/views/form-layouts/form-action/FLFormAction"),
        // },
        // {
        //   name: "FLFormBasic",
        //   path: "form-layouts/FLFormBasic",
        //   component: () =>
        //     import("@/views/form-layouts/form-basic/FLFormBasic"),
        // },
        // {
        //   name: "FormWizard",
        //   path: "form-layouts/FormWizard",
        //   component: () =>
        //     import("@/views/form-layouts/form-wizard/FormWizard"),
        // },
        {
          name: "DragnDrop",
          path: "extra-components/DragnDrop",
          component: () =>
            import("@/views/extra-components/drag-n-drop/DragnDrop"),
        },
        // {
        //   name: "FLFormHorizontal",
        //   path: "form-layouts/FLFormHorizontal",
        //   component: () =>
        //     import("@/views/form-layouts/form-horizontal/FLFormHorizontal"),
        // },
        // {
        //   name: "TableSimple",
        //   path: "tables/TableSimple",
        //   component: () => import("@/views/tables/table-simple/TableSimple"),
        // },
        // {
        //   name: "TableDataIterators",
        //   path: "tables/TableDataIterators",
        //   component: () =>
        //     import("@/views/tables/table-data-iterators/TableDataIterators"),
        // },
        // {
        //   name: "TableDatatable",
        //   path: "tables/TableDatatable",
        //   component: () =>
        //     import("@/views/tables/table-data-table/TableDatatable"),
        // },
        // {
        //   name: "FormValidation",
        //   path: "form-layouts/FormValidation",
        //   component: () =>
        //     import("@/views/form-layouts/form-validation/FormValidation"),
        // },
        // {
        //   name: "MaterialIcons",
        //   path: "icons/MaterialIcons",
        //   component: () => import("@/views/icons/MaterialIcons"),
        // },
        // {
        //   name: "ThemifyIcons",
        //   path: "icons/ThemifyIcons",
        //   component: () => import("@/views/icons/ThemifyIcons"),
        // },
        // {
        //   name: "FontAwesomeIcons",
        //   path: "icons/FontAwesomeIcons",
        //   component: () => import("@/views/icons/FontAwesomeIcons"),
        // },
        // {
        //   name: "SimpleLineIcons",
        //   path: "icons/SimpleLineIcons",
        //   component: () => import("@/views/icons/SimpleLineIcons"),
        // },
      ],
    },

    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        // {
        //   name: "BoxedLogin",
        //   path: "boxedlogin",
        //   component: () => import("@/views/authentication/BoxedLogin"),
        // },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
          meta: {
            auth: false,
          },
        },
        // {
        //   name: "BoxedRegister",
        //   path: "boxedregister",
        //   component: () => import("@/views/authentication/BoxedRegister"),
        // },
        {
          path: "resetpassword/:token/:id/:email",
          name: "resetpassword",
          component: () => import("@/views/authentication/ResetPassword"),
          props: true,
          meta: {
            auth: false,
          },
        },
        {
          name: "ForgotPassword",
          path: "forgotpassword",
          component: () => import("@/views/authentication/ForgotPassword"),
        },
        // {
        //   name: "Error",
        //   path: "error",
        //   component: () => import("@/views/authentication/Error"),
        // },
      ],
    },
    {
      path: "/payment",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "SelectPackage",
          path: "selectpackage",
          component: () => import("@/views/payment/SelectPackage"),
        },
        {
          name: "BeforePayment",
          path: "beforepayment",
          component: () => import("@/views/payment/BeforePayment"),
          props: true,
        },
        {
          name: "SuccesPayment",
          path: "succespayment",
          component: () => import("@/views/payment/SuccesPayment"),
        },
      ],
    },
    {
      path: "/registration",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Request",
          path: "request/:token/:id/:email",
          component: () => import("@/views/registration/Request"),
          props: true,
        },
        {
          name: "SuccesValidation",
          path: "succesvalidation",
          component: () => import("@/views/registration/SuccesValidation"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {

    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
