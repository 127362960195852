/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  chatPartners: [],
  currentChatMessages: [],
  hasUnreadMessages: false,
};

// getters
const getters = {
  getChatPartners: (state) => {
    return state.chatPartners;
  },
  getCurrentChatMessages: (state) => {
    return state.currentChatMessages.sort((a, b) =>
      a.sendTime.localeCompare(b.sendTime)
    );
    //return state.currentChatMessages;
  },
  getHasMessages: () => {
    return state.hasUnreadMessages;
    //return state.currentChatMessages;
  },
};

// actions
const actions = {
  //get all new registration
  fetchChatPartners({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Chats")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CHAT_PARTNERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createMessage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Chats", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("CREATE_CHAT_MESSAGE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createMultipleMessage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Chats/multiple", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_CHAT_MESSAGE_MULTIPLE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchMessages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Chats/all", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CHAT_MESSAGES", res.data);
        })
        .catch((err) => {
          if (err.response.status == 404) commit("EMPTY_CHAT_MESSAGE"); //empty result
          reject(err);
        });
    });
  },
  fetchHasUnreadMessages({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Chats/unread")
        .then((res) => {
          commit("FETCH_HAS_UNREAD_MESSAGES", res.data);
          setTimeout(() => resolve(res.data), 1500);
        })
        .catch((err) => {
          if (err.response.status == 404) commit("EMPTY_CHAT_MESSAGE"); //empty result
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_CHAT_PARTNERS(state, data) {
    state.chatPartners = data;
  },
  FETCH_CHAT_MESSAGES(state, data) {
    state.currentChatMessages = data;
  },
  CREATE_CHAT_MESSAGE(state, data) {
    state.currentChatMessages.unshift(data);
  },
  EMPTY_CHAT_MESSAGE(state) {
    state.currentChatMessages = [];
  },
  FETCH_HAS_UNREAD_MESSAGES(state, data) {
    state.hasUnreadMessages = data;
  },
  CREATE_CHAT_MESSAGE_MULTIPLE() {
    //niks
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
