/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  coaches: [],
  coachReports: [],
  coachReportActivities: [],
  resends: [],
  priceSettings: {}
};

// getters
const getters = {
  getCoaches: (state) => {
    return state.coaches;
  },
  getCoachReports: (state) => {
    return state.coachReports;
  },
  getCoachReportActivities: (state) => {
    return state.coachReportActivities;
  },
  getPriceSettings: (state) => {
    return state.priceSettings;
  },
};

// actions
const actions = {
  //get all current coaches
  fetchCoaches({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/adminCoaches")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACHES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/adminCoaches/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_REPORT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchOwnCoachReport({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/adminCoaches/own")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_REPORT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachReportActivities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/adminCoaches/activities/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_REPORT_ACTIVITIES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/adminCoaches", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_COACH", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendRegistration({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/adminCoaches/resendregistration", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_RESEND", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Settings
  fetchCoachPriceSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/adminCoaches/setting/prices/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_PRICE_SETTINGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCoachPriceSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/adminCoaches/setting/prices", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_PRICE_SETTINGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_COACHES(state, data) {
    state.coaches = data;
  },
  FETCH_COACH_REPORT(state, data) {
    state.coachReports = data;
  },
  FETCH_COACH_REPORT_ACTIVITIES(state, data) {
    state.coachReportActivities = data;
  },
  CREATE_COACH(state, data) {
    state.coaches.unshift(data);
  },
  CREATE_RESEND(state, data) {

    state.resends.unshift(data);
  },
  // Settings
  FETCH_COACH_PRICE_SETTINGS(state, data) {
    state.priceSettings = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
