<template>
  <v-card :class="noPadding ? 'mb-0' : 'mb-7'">
    <v-toolbar flat :color="color" v-if="!noHeader">
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider v-if="!noHeader"></v-divider>
    <div :class="noPadding ? 'px-3' : 'pa-4'">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",

  props: {
    heading: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),

  methods: {},
};
</script>