/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
    coachViews: [],
    coacheeViews: [],
    filterViews: [],
    businessSettings: {},
    socialsSettings: {},
};

// getters
const getters = {
    getCoachViews: (state) => {
        return state.coachViews;
    },
    getCoacheeViews: (state) => {
        return state.coacheeViews;
    },
    getFilterViews: (state) => {
        return state.filterViews;
    },
    getBusinessSettings: (state) => {
        return state.businessSettings;
    },
    getSocialsSettings: (state) => {
        return state.socialsSettings;
    },
};

// actions
const actions = {
    //get all current coaches
    fetchCoachViews({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/views")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_VIEWS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    fetchCoacheeViews({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/views/coachee/" + payload.userId)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_COACHEE_VIEWS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    fetchFilterViews({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/views/filterViews")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_FILTER_VIEWS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    createView({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/views", payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("CREATE_VIEW", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateView({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("api/views/" + payload.userId, payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_COACHEE_VIEWS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteView({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/views/" + payload.id, payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("DELETE_VIEW", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    /* --- Business Settings --- */
    fetchBusinessSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/usersettings/coach/business")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_BUSINESS_SETTINGS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    fetchCoachBusinessSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/usersettings/coach/business/coachee")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_BUSINESS_SETTINGS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateBusinessSettings({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("api/usersettings/coach/business/" + payload.coachSettingId, payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_BUSINESS_SETTINGS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    /* --- Socials Settings --- */
    fetchSocialsSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/usersettings/coach/socials")
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_SOCIALS_SETTINGS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateSocialsSettings({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("api/usersettings/coach/socials/" + payload.id, payload)
                .then((res) => {
                    setTimeout(() => resolve(res.data), 250);
                    commit("FETCH_SOCIALS_SETTINGS", res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

};

// mutations
const mutations = {
    FETCH_COACHEE_VIEWS(state, data) {
        state.coacheeViews = data;
    },
    FETCH_VIEWS(state, data) {
        state.coachViews = data;
    },
    CREATE_VIEW(state, data) {
        state.coachViews.unshift(data);
    },
    DELETE_VIEW(state, data) {
        let index = state.coachViews.findIndex((item) => item.id === data.id);
        state.coachViews.splice(index, 1);
    },
    FETCH_FILTER_VIEWS(state, data) {
        state.filterViews = data;
    },
    FETCH_BUSINESS_SETTINGS(state, data) {
        state.businessSettings = data;
    },
    FETCH_SOCIALS_SETTINGS(state, data) {
        state.socialsSettings = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
