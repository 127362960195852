/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  users: [],
  registrations: [],
  currentUser: {},
  password: {},
  mailsetting: {},
  userStatus: {},
  messageSetting: {},
  notificationSetting: {},
  userNextActions: {},
};

// getters
const getters = {
  getUsers: (state) => {
    return state.users;
  },
  getRegistrations: (state) => {
    return state.registrations;
  },
  getCurrentUser: (state) => {
    return state.currentUser;
  },
  getCurrentMailsetting: (state) => {
    return state.mailsetting;
  },
  getCurrentMessagesetting: (state) => {
    return state.messageSetting;
  },
  getCurrentNotificationsetting: (state) => {
    return state.notificationSetting;
  },
  getUserStatus: (state) => {
    return state.userStatus;
  },
  getUserNextActions: (state) => {
    return state.userNextActions;
  },
};

// actions
const actions = {
  //get all current users
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/users")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoachUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("FETCH_USERS", []); //clear
      axios
        .get("api/users/coach/" + payload.coachId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSubcoachUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/users/subcoach/" + payload.coachId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USERS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createUser({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/register", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //get all new registration
  fetchRegistrations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/users/registrations")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_REGISTRATIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  acceptRegistration({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/users/accept/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_REGISTRATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createRegistration({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/register", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_REGISTRATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteRegistration({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Users/delete/registration/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_REGISTRATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/current")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserMailSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/setting/mail/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER_MAILSETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserMessageSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/UserSettings/message/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER_MESSAGE_SETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNotificationSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/UserSettings/notification/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER_NOTIFICATION_SETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editUserMailSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Users/setting/mail", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_MAILSETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editUserMessageSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/UserSettings/message", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_MESSAGE_SETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editUserNotificationSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/UserSettings/notification", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NOTIFICATION_SETTING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCurrentUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Users/put", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/get/" + userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  ToggleUserActive({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Users/toggleActive/" + userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Users/put/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteUser({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Users/delete/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserStatus({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/currentstatus")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER_STATUS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserNextActions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/current/nextactions")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_USER_NEXT_ACTIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  newZeroReading({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/new")
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADINGNEW", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createZeroReading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCurrentProgressZeroReading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/saveprogress", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validateEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/validateemail", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("VALIDATE_EMAIL", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/validateuser", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("VALIDATE_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  forgotPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/forgotpassword", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FORGOT_PASSWORD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/resetpassword", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FORGOT_PASSWORD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendRegistration({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/login/resendregistration", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_REGISTRATION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  reopenZeroReading({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/reopenZeroReading", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("REOPEN_ZERO_READING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  revokeToken({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/token/revoke")
        .then(() => {
          setTimeout(() => resolve(), 200);
          commit("REVOKE_TOKEN");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_USERS(state, data) {
    state.users = data;
  },
  FETCH_REGISTRATIONS(state, data) {
    state.registrations = data;
  },
  FETCH_USER_STATUS(state, data) {
    state.userStatus = data;
  },
  DELETE_REGISTRATION(state, data) {
    let index = state.registrations.findIndex((item) => item.id === data.id);
    state.registrations.splice(index, 1);
  },
  CREATE_USER(state, data) {
    state.users.unshift(data);
  },
  CREATE_REGISTRATION(state, data) {
    state.registrations.unshift(data);
  },
  FETCH_USER(state, data) {
    state.currentUser = data;
  },
  FETCH_USER_MAILSETTING(state, data) {
    state.mailsetting = data;
  },
  FETCH_USER_MESSAGE_SETTING(state, data) {
    state.messageSetting = data;
  },
  FETCH_USER_NOTIFICATION_SETTING(state, data) {
    state.notificationSetting = data;
  },
  DELETE_USER(state, data) {
    let index = state.users.findIndex((item) => item.id === data.id);
    state.users.splice(index, 1);
  },
  FORGOT_PASSWORD(state, data) {
    state.password = data;
  },
  REVOKE_TOKEN(state) {
    state.password = "";
  },
  REOPEN_ZERO_READING() {
    //
  },
  FETCH_USER_NEXT_ACTIONS(state, data) {
    state.userNextActions = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
