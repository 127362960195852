/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  uploads: [],
  upload: null,
  uploadFeedback: [],
  activeUploads: [],
};

// getters
const getters = {
  getUploads: (state) => {
    return state.uploads;
  },
  getUploadFeedback: (state) => {
    return state.uploadFeedback;
  },
  getActiveUploads: (state) => {
    return state.activeUploads;
  },
};

// actions
const actions = {
  //get all new registration
  createUpload({ commit }, payload) {
    //create upload object

    return new Promise((resolve, reject) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      commit("CREATE_UPLOAD_PROGESS", payload);
      let latestObject = state.activeUploads[0];
      axios
        .post("api/Upload/uploadcontent", payload, config)
        .then((res) => {
          commit("UPDATE_UPLOAD_PROGESS", latestObject);
          commit("CREATE_UPLOAD", res.data);
          commit("REMOVE_UPLOAD_PROGESS", latestObject);
          setTimeout(() => resolve(res.data), 250);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeUploadProgress({ commit }, payload) {
    commit("REMOVE_UPLOAD_PROGESS", payload);
  },
  deleteUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Upload/file/" + payload.name)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_UPLOAD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCoacheeUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Upload/file/" + payload.name + "/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_UPLOAD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUploads({ commit }, payload) {
    var isCoach = payload.roles.includes("Coach") || payload.roles.includes("SubCoach");
    var isDeveloper = payload.roles.includes("Developer");
    let baseLink = isCoach ? "api/Upload/all/" : "api/Upload/";
    baseLink = isDeveloper ? "api/Upload/all/dev/" : baseLink;
    return new Promise((resolve, reject) => {
      axios
        .get(baseLink + payload.type + "/" + payload.exercise)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_UPLOADS", res.data.uploads);
        })
        .catch((err) => {
          commit("FETCH_UPLOADS", []);
          reject(err);
        });
    });
  },
  fetchCoacheeUploads({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/coachee/" + payload.id + "/" + payload.type)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_UPLOADS", res.data.uploads);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProgessUploads({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/uploads/progress")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_UPLOADS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProgessUploadsCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/uploads/progress/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_UPLOADS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  fetchSecureUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/progress/" + payload.name, { responseType: "blob" })
        .then((res) => {
          setTimeout(() => resolve(res), 250);
          commit("FETCH_UPLOAD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSecureUploadCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/coach/progress/" + payload.name, { responseType: "blob" })
        .then((res) => {
          setTimeout(() => resolve(res), 250);
          commit("FETCH_UPLOAD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/file/" + payload.name, { responseType: "blob" })
        .then((res) => {
          setTimeout(() => resolve(res), 1500);
          commit("FETCH_UPLOAD", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //feedback
  createUploadFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Upload/Feedback", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          //console.log("res.data", res.data);
          commit("CREATE_UPLOAD_FEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUploadFeedbackByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Upload/feedback/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_UPLOAD_FEEDBACK", res.data);
        })
        .catch((err) => {
          commit("FETCH_UPLOAD_FEEDBACK", []);
          reject(err);
        });
    });
  },
  rateUploadFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Upload/Feedback/rate", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_UPLOAD_FEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  CREATE_UPLOAD(state, data) {
    state.uploads.unshift(data);
  },
  FETCH_UPLOADS(state, data) {
    state.uploads = data;
  },
  FETCH_UPLOAD(state, data) {
    state.upload = data;
  },
  DELETE_UPLOAD(state, data) {
    let index = state.uploads.findIndex((item) => item.id === data.id);
    state.uploads.splice(index, 1);
  },
  CREATE_UPLOAD_FEEDBACK(state, data) {
    state.uploadFeedback.unshift(data);
  },
  FETCH_UPLOAD_FEEDBACK(state, data) {
    state.uploadFeedback = data;
  },
  UPDATE_UPLOAD_FEEDBACK(state, data) {
    state.uploadFeedback = [
      ...state.uploadFeedback.filter((element) => element.id !== data.id),
      data,
    ];
    state.uploadFeedback.sort((a, b) => b.sendtime.localeCompare(a.sendtime));
  },
  CREATE_UPLOAD_PROGESS(state, data) {
    let formData = Object.fromEntries(data);
    let time = formData.File.size / 100;
    let object = {
      title: formData.name,
      iconbg: "primary",
      icon: "mdi-calendar-check",
      time: time,
      startTimer: false,
      loading: true,
      uuid: create_UUID(),
    };
    state.activeUploads.unshift(object);

    setTimeout(() => {
      object.startTimer = true;
      state.activeUploads = [
        ...state.activeUploads.filter(
          (element) => element.uuid !== object.uuid
        ),
        object,
      ];
    }, 100);
  },
  UPDATE_UPLOAD_PROGESS(state, data) {
    data.loading = false;
    data.iconbg = "green darken-1";
    state.activeUploads = [
      ...state.activeUploads.filter((element) => element.uuid !== data.uuid),
      data,
    ];
  },
  REMOVE_UPLOAD_PROGESS(state, data) {
    setTimeout(() => {
      let index = state.activeUploads.findIndex(
        (item) => item.uuid === data.uuid
      );

      state.activeUploads.splice(index, 1);
    }, 2000);
  },
};
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
