/*=========================================================================================
  File Name: validationConfig.js
  Description: validation configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - Clean Vue.js Dashboard Admin Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from "vue";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
import contentRules from "./contentConfig";

//Change field name conflicting names
Vue.use(VeeValidate, { fieldsBagName: "formFields" });

//validation messages
const dictionary = {
  custom: {
    email: {
      required: "Dit veld mag niet leeg zijn",
      min: "Je email kan niet kleiner zijn dan 3 karakters",
      email: "dit emailadres is niet geldig",
    },
    firstname: {
      required: "Dit veld mag niet leeg zijn",
      min: "Dit kan niet kleiner zijn dan 1 karakter",
      name: "dit is niet geldig ",
      is_not: "test",
      numeric: "tes231",
    },
    lastname: {
      required: "Dit veld mag niet leeg zijn",
      min: "Dit kan niet kleiner zijn dan 2 karakters",
      name: "dit is niet geldig ",
    },
    gender: {
      required: "Kies een geslacht alstublieft",
      name: "dit is niet geldig ",
    },
    birthday: {
      required: "Dit veld mag niet leeg zijn",
    },
    phone: {
      required: "Dit veld mag niet leeg zijn",
      verify_phone_dutch: "Geen geldig telefoonnummer",
    },
    zipcode: {
      required: "Dit veld mag niet leeg zijn",
      verify_zipcode_dutch: "Geen geldig postcode",
    },
    housenumber: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Een huisnummer mag alleen getallen bevatten"
    },
    password_confirmation: {
      required: "Dit veld mag niet leeg zijn",
      confirmed: "Het opgegeven wachtwoord komt niet overeen",
      min: "Je wachtwoord kan niet kleiner zijn dan 8 karakters",
      max: "Je wachtwoord kan niet groter zijn dan 20 karakters",
    },
    date: {
      required: "Kies uw geboortedatum alstublieft",
      name: "dit is niet geldig ",
    },
    initials: {
      required: "Dit veld mag niet leeg zijn",
      min: "Dit kan niet kleiner zijn dan 1 karakter",
      name: "dit is niet geldig ",
    },
    password: {
      required: "Dit veld mag niet leeg zijn",
      min: "Je wachtwoord kan niet kleiner zijn dan 6 karakters",
      max: "Je wachtwoord kan niet groter zijn dan 20 karakters",
      verify_password:
        "Je wachtwoord moet minstens: 1 hoofdletter, 1 kleine letter, 1 nummer en een speciale karakter (Bijvoorbeeld , . _ & ? etc) bevatten.",
    },
    confirm_password: {
      confirmed: "Het opgegeven wachtwoord komt niet overeen",
      required: "Dit veld mag niet leeg zijn",
      min: "Je wachtwoord kan niet kleiner zijn dan 6 karakters",
      max: "Je wachtwoord kan niet groter zijn dan 20 karakters",
    },
    username: {
      required: "Dit veld mag niet leeg zijn",
      max: "Je gebruikersnaam kan niet groter zijn dan 30 karakters",
      alpha_dash:
        "Een gebruikersnaam mag alleen alphabetische karakters, cijfers, - of _ bevatten.",
    },
    termscheck: {
      required: "Dit veld mag niet leeg zijn",
    },
    fullname: {
      alpha_spaces: "De naam kan alleen alphabetische karakters bevatten",
      max: "Je gebruikersnaam kan niet groter zijn dan 100 karakters",
    },
    description: {
      required: "Dit veld mag niet leeg zijn",
      min: "De bescrijving kan niet kleiner zijn dan 10 karakters",
      max: "De bescrijving kan niet groter zijn dan 240 karakters",
    },
    //Lengtes
    length: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    weight: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    abdominalSize: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    armLeft: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    armRight: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    legLeft: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    rightLeg: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    hipSize: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    fatPercentage: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    bloodPressureSys: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    bloodPressureDia: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    //Zero reading food
    sigaretAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    alcoholAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    meatAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    fishAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    dietExperience: {
      required: "Dit veld mag niet leeg zijn",
    },
    dailyFood: {
      required: "Dit veld mag niet leeg zijn",
    },
    foodGoals: {
      required: "Dit veld mag niet leeg zijn",
    },
    //Zero reading movement and sport
    sportAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    moreSportExplanation: {
      required: "Dit veld mag niet leeg zijn",
    },
    sportHistory: {
      required: "Dit veld mag niet leeg zijn",
    },
    otherSportAmount: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    exercisesPrevNot: {
      required: "Dit veld mag niet leeg zijn",
    },
    exercisesPrefer: {
      required: "Dit veld mag niet leeg zijn",
    },
    exercisesOther: {
      required: "Dit veld mag niet leeg zijn",
    },
    daysSelected: {
      required: "Dit veld mag niet leeg zijn",
    },
    //Zero reading injuries
    underTreatment: {
      required: "Dit veld mag niet leeg zijn",
    },
    conditionSelected: {
      required: "Dit veld mag niet leeg zijn",
    },
    amountPerWeek: {
      required: "Dit veld mag niet leeg zijn",
    },
    //Zero reading lifestyle
    dailyLife: {
      required: "Dit veld mag niet leeg zijn",
    },
    stressLevel: {
      required: "Dit veld mag niet leeg zijn",
    },
    sleep: {
      required: "Dit veld mag niet leeg zijn",
    },
    mirrorEvaluationText: {
      required: "Dit veld mag niet leeg zijn",
    },
    //Zero reading goals
    goals: {
      required: "Dit veld mag niet leeg zijn",
    },
    achieveGoals: {
      required: "Dit veld mag niet leeg zijn",
    },
    whatOnAchieveGoals: {
      required: "Dit veld mag niet leeg zijn",
    },
    evalImportant: {
      required: "Dit veld mag niet leeg zijn",
    },
    leaveBehind: {
      required: "Dit veld mag niet leeg zijn",
    },
    explainLeaveBehind: {
      required: "Dit veld mag niet leeg zijn",
    },
    evalFailedGoals: {
      required: "Dit veld mag niet leeg zijn",
    },
    evalSucceedGoals: {
      required: "Dit veld mag niet leeg zijn",
    },
    simpleNumeric: {
      required: "Dit veld mag niet leeg zijn",
      numeric: "Dit veld moet een nummer zijn",
    },
    optiondescription: {
      required: "Dit veld mag niet leeg zijn",
    },
    optionname: {
      required: "Dit veld mag niet leeg zijn",
    },
    trainingname: {
      required: "Dit veld mag niet leeg zijn",
    },
    exercisename: {
      required: "Dit veld mag niet leeg zijn",
    },
    exercisedescription: {
      required: "Dit veld mag niet leeg zijn",
    },
    fileinput: {
      required: "Dit veld mag niet leeg zijn",
      ext:
        "Alleen de volgende bestandstypen worden ondersteund: " +
        contentRules.allowedFileTypes.toString(),
      size:
        "Alleen bestanden onder de " +
        bytesToSizesLocal(contentRules.maxByteSize).toString() +
        " worden ondersteund",
    },
    advicetitle: {
      required: "Dit veld mag niet leeg zijn",
    },
    adviceText: {
      required: "Dit veld mag niet leeg zijn",
    },
    adviceTextEdit: {
      required: "Dit veld mag niet leeg zijn",
    },
    adviceGroupText: {
      required: "Dit veld mag niet leeg zijn",
    },
    adviceGroupTitle: {
      required: "Dit veld mag niet leeg zijn",
    },
    newExerciseName: {
      required: "Dit veld mag niet leeg zijn",
    },
    newExerciseDescription: {
      required: "Dit veld mag niet leeg zijn",
    },
    viewName: {
      required: "Dit veld mag niet leeg zijn",
    },
    viewShortname: {
      required: "Dit veld mag niet leeg zijn",
      max: "maximaal aantal karakters van 12, kies een kortere naam",
      alpha: "Alleen alfabetische karakters, geen spaties"
    },
    productname: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    carbsamount: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    fatamount: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    proteinamount: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    caloriesamount: {
      required: "Dit veld mag niet leeg zijn",
      numeric_comma: "Dit veld moet een nummer zijn",
    },
    imagesource: {
      url: "Geen geldige URL."
    },
    websiteLink: {
      url: "Geen geldige URL.",
      required: "Dit veld mag niet leeg zijn",
    },
    linkText: {
      required: "Dit veld mag niet leeg zijn",
      max: `Maximaal aantal karakters van 20, kies een kortere naam`,
    },
    companyDisplay: {
      required: "Dit veld mag niet leeg zijn",
      max: `Maximaal aantal karakters van 40, kies een kortere naam`,
    },
    appName: {
      required: "Dit veld mag niet leeg zijn",
      max: `Maximaal aantal karakters van 20, kies een kortere naam`,
    },
    productfileinput: {
      required: "Dit veld mag niet leeg zijn",
      ext:
        "Alleen de volgende bestandstypen worden ondersteund: " +
        contentRules.allowedProductFileTypes.toString(),
      size:
        "Alleen bestanden onder de " +
        bytesToSizesLocal(contentRules.maxProductByteSize).toString() +
        " worden ondersteund",
    },
  },
};

function bytesToSizesLocal(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  return Math.round((bytes / Math.pow(1000, i)) * 10, 2) / 10 + " " + sizes[i];
}

Validator.localize("en", dictionary);

//Custom validation checks
VeeValidate.Validator.extend("verify_password", {
  validate: (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$_,.%:;~^&*-])(?=.)"
    );
    return strongRegex.test(value);
  },
});

VeeValidate.Validator.extend("verify_zipcode_dutch", {
  validate: (value) => {
    var strongRegexnl = new RegExp("^[1-9][0-9]{3}[s]?[A-Za-z]{2}$");
    var strongRegexbe = new RegExp("^[1-9]{1}[0-9]{3}$");
    return strongRegexnl.test(value) || strongRegexbe.test(value);
  },
});

VeeValidate.Validator.extend("numeric_comma", {
  validate: (value) => {
    var strongRegex = new RegExp("^[0-9]{1,9}([,.][0-9]{1,9})?$");
    return strongRegex.test(value)
  },
});

//temp for easy fix
VeeValidate.Validator.extend("requiredd", {
  validate: () => {
    return true;
  },
});

VeeValidate.Validator.extend("verify_phone_dutch", {
  validate: (value) => {
    var homePhone = new RegExp(
      "^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$"
    );
    var mobilePhone = new RegExp("^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$");
    var belgiumphone = new RegExp("^[0-9]{13}$");
    return homePhone.test(value) || mobilePhone.test(value) || belgiumphone.test(value);
  },
});

export default dictionary;
