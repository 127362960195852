/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  trainings: [],
  currenttraining: {},
  trainingDates: [],
  trainingFeedback: [],
  currentFilledTraining: {},
  currentCoachTraining: {},
  currentCoachTrainingFeedback: {},
  currentCoachEditingTraining: {},
  currentTrainingOptions: {},
  currentCoachEditingIndex: 0,
  trainingDatesFeedback: []
};

// getters
const getters = {
  getTrainings: (state) => {
    return state.trainings;
  },
  getCurrentTraining: (state) => {
    return state.currenttraining;
  },
  getCurrentTrainingOptions: (state) => {
    return state.currentTrainingOptions;
  },
  getCurrentFilledTraining: (state) => {
    return state.currentFilledTraining;
  },
  getCurrentCoachTrainingFeedback: (state) => {
    return state.currentCoachTrainingFeedback;
  },
  getCurrentCoachTraining: (state) => {
    return state.currentCoachTraining;
  },
  getCurrentCoachEditingTraining: (state) => {
    return state.currentCoachEditingTraining;
  },
  getCurrentCoachEditingIndex: (state) => {
    return state.currentCoachEditingIndex;
  },
  getTrainingDates: (state) => {
    return state.trainingDates;
  },
  getTrainingDatesFeedback: (state) => {
    return state.trainingDatesFeedback;
  },
  getTrainingFeedback: (state) => {
    return state.trainingFeedback.sort((a, b) =>
      b.sendtime.localeCompare(a.sendtime)
    );
    //return state.trainingFeedback;
  },
};

// actions
const actions = {
  //get all new registration
  fetchTrainings({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/user/" + id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_TRAININGS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Trainings", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("CREATE_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createTrainingFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Trainings/Feedback", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          //console.log("res.data", res.data);
          commit("CREATE_TRAININGFEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  rateTrainingFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Trainings/Feedback/rate", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("UPDATE_TRAININGFEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  putTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Trainings/full/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("CREATE_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Trainings/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("DELETE_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  toggleActiveTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/trainings/toggleActive/" + payload.id, payload)
        .then((res) => {
          commit("EDIT_TRAINING", res.data);
          setTimeout(() => resolve(res.data), 1500);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchFullTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/full/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCurrentTrainingByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/current/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING_OPTIONS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCurrentTrainingByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Trainings/current", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("UPDATE_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingFeedbackByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/feedback/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_TRAININGFEEDBACK", res.data);
        })
        .catch((err) => {
          commit("FETCH_TRAININGFEEDBACK", []);
          reject(err);
        });
    });
  },
  fetchTrainingByDateAndUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/date/" + payload.date + "/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingDatesWithFeedbackByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/dates/feedback/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_TRAINING_DATES_FEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingDatesByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/dates/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_TRAINING_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //coachee
  fetchCurrentTraining({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/current")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCurrentTrainingDisplay({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/user")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingDisplay({ commit }, trainingId) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/full/" + trainingId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingByDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/date/" + payload.date)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTrainingDates({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/dates")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_TRAINING_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTrainingDate({ commit }, payload) {
    commit("ADD_TRAINING_DATE", payload.date);
  },
  createUserTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Trainings/User", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_TRAINING_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createUserTrainingCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/Trainings/User/${payload.userId}`, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CREATE_TRAINING_USER", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setCurrentFilledTraining({ commit }, payload) {
    commit("SET_CURRENT_FILLED_TRAINING", payload);
  },
  setCurrentCoachTraining({ commit }, payload) {
    commit("SET_CURRENT_COACH_TRAINING", payload);
  },
  setCurrentCoachTrainingFeedback({ commit }, payload) {
    commit("SET_CURRENT_COACH_TRAINING_FEEDBACK", payload);
  },
  setCurrentCoachEditingTraining({ commit }, payload) {
    commit("SET_CURRENT_COACH_EDITING_TRAINING", payload);
  },
  setCurrentCoachEditingIndex({ commit }, payload) {
    commit("SET_CURRENT_COACH_EDITING_INDEX", payload);
  },
  fetchActiveTraining({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Trainings/active/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("FETCH_CURRENT_TRAINING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_TRAININGS(state, data) {
    state.trainings = data;
  },
  FETCH_CURRENT_TRAINING(state, data) {
    state.currenttraining = data;
  },
  UPDATE_CURRENT_TRAINING(state, data) {
    state.currenttraining = data;
  },
  FETCH_TRAININGFEEDBACK(state, data) {
    state.trainingFeedback = data;
  },
  FETCH_TRAINING_DATES(state, data) {
    state.trainingDates = data;
  },
  FETCH_TRAINING_DATES_FEEDBACK(state, data) {
    state.trainingDatesFeedback = data;
  },
  ADD_TRAINING_DATE(state, date) {
    state.trainingDates.unshift(date);
  },
  CREATE_TRAININGFEEDBACK(state, data) {
    state.trainingFeedback.unshift(data);
  },
  UPDATE_TRAININGFEEDBACK(state, data) {
    state.trainingFeedback = [
      ...state.trainingFeedback.filter((element) => element.id !== data.id),
      data,
    ];
  },
  CREATE_TRAINING(state, data) {
    state.trainings.unshift(data);
  },
  CREATE_TRAINING_USER(state, data) {
    //state.trainings.unshift(data);
    console.log(state, data);
  },
  EDIT_TRAINING(state, data) {
    state.trainings = data;
  },
  DELETE_TRAINING(state, data) {
    // console.log("DELETE_TRAINING", data);
    let index = state.trainings.findIndex((item) => item.id === data.id);
    // console.log("STATE", state.trainings);
    state.trainings.splice(index, 1);
  },
  SET_CURRENT_FILLED_TRAINING(state, data) {
    state.currentFilledTraining = data;
  },
  SET_CURRENT_COACH_TRAINING(state, data) {
    state.currentCoachTraining = data;
  },
  SET_CURRENT_COACH_TRAINING_FEEDBACK(state, data) {
    state.currentCoachTrainingFeedback = data;
  },
  SET_CURRENT_COACH_EDITING_TRAINING(state, data) {
    state.currentCoachEditingTraining = data;
  },
  SET_CURRENT_COACH_EDITING_INDEX(state, data) {
    state.currentCoachEditingIndex = data;
  },
  FETCH_CURRENT_TRAINING_OPTIONS(state, data) {
    state.currentTrainingOptions = data;
    state.currenttraining = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
