import Vue from "vue";
import moment from "moment";

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("lowercase", function (value) {
  if (!value) return "";
  return value.toLowerCase();
});

Vue.filter("bytesToSizes", function (bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  return Math.round((bytes / Math.pow(1000, i)) * 10, 2) / 10 + " " + sizes[i];
});

Vue.filter("truncate", function (value, limit) {
  if (!isNaN(parseFloat(value)) && isFinite(value)) return value;
  if (value == undefined) return "";
  return value.substring(0, limit);
});

Vue.filter("tailing", function (value, tail) {
  return value + tail;
});

Vue.filter("time", function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return hours + ":" + min + " " + time;
    }
    return hours + ":" + min;
  }
});

Vue.filter("date", function (value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + " " + month;
  else return date + " " + month + " " + year;
});

Vue.filter("regularDate", function (value) {
  value = String(value);

  let date = moment(value);
  if (date.isValid())
    return date.format("DD-MM-YYYY");
  else
    return "Ongeldige datum";
});

Vue.filter("regularTime", function (value) {
  value = String(value);

  return moment(value).format("HH:mm");
});

Vue.filter("regularDateTime", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("DD-MM-YYYY HH:mm");

  return moment(value).format("DD-MM-YYYY HH:mm");
});

//fullDateTime
Vue.filter("fullDateTime", function (value) {
  value = String(value);

  return moment(value).format("DD MMMM YYYY HH:mm");
});

//smallDate
Vue.filter("smallDate", function (value, addDay) {
  value = String(value);
  if (addDay == undefined) addDay = 0;

  return moment(value)
    .add("Day", addDay)
    .format("DD MMMM");
});

//smallerDate
Vue.filter("smallerDate", function (value, addDay) {
  value = String(value);
  if (addDay == undefined) addDay = 0;

  return moment(value)
    .add("Day", addDay)
    .format("DD MMM");
});

Vue.filter("dateAdd", function (value, addDay) {
  value = String(value);
  if (addDay == undefined) addDay = 0;

  return moment(value).add("Day", addDay);
});

Vue.filter("regularDateClean", function (value) {
  var minDate = moment.utc("0001-01-01");
  if (value == undefined) return moment();
  value = String(value);
  if (isValidTimestamp(value)) {
    return moment.unix(value / 1000).format("DD MMMM YYYY");
  }

  //check if date is low
  if (moment.utc(value).isAfter(minDate)) {
    return moment(value).format("DD MMMM YYYY");
  } else {
    return "Geen datum";
  }
});

Vue.filter("regularDateTimeClean", function (value) {
  value = String(value);
  if (isValidTimestamp(value))
    return moment.unix(value / 1000).format("DD MMMM YYYY HH:mm");

  return moment(value).format("DD MMMM YYYY HH:mm");
});

Vue.filter("ReadableMonth", function (value) {
  value = String(value);

  // get from-now for this date
  //var fromNow = moment(value).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(value).calendar(null, {
    // when the date is closer, specify custom values
    lastMonth: "[Vorige maand]",
    sameMonth: "[Deze maand]",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "MMMM YYYY";
    },
  });
});

Vue.filter("ReadableDate", function (value) {
  value = String(value);

  // get from-now for this date
  //var fromNow = moment(value).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(value).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Afgelopen] dddd",
    lastDay: "[Gisteren]",
    sameDay: "[Vandaag]",
    nextDay: "[Morgen]",
    nextWeek: "D MMMM YYYY",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "D MMMM YYYY";
    },
  });
});

Vue.filter("day", function (value) {
  value = String(value);

  return moment(value).format("dddd");
});

Vue.filter("month", function (val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + " " + regx.exec(val)[2];
  }
});

Vue.filter("formatGender", function (val) {
  val = String(val);

  if (val == "0") {
    return "Man";
  }
  if (val == "1") {
    return "Vrouw";
  } else {
    return "Onbekend";
  }
});

Vue.filter("genderColor", function (val) {
  val = String(val);

  if (val == "0") {
    return "blue lighten-2";
  }
  if (val == "1") {
    return "pink lighten-2";
  } else {
    return "grey lighten-2";
  }
});

Vue.filter("csv", function (value) {
  if (!value) return "";
  return value.join(", ");
});

Vue.filter("filter_tags", function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function (num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
});

Vue.filter("formatPhone", function (val) {
  if (val == undefined || val == "") return "";
  var parts = val.match(/.{1,2}/g);
  var new_value = parts.join(" "); //returns 123-456-789
  return "+" + new_value;
});

Vue.filter("formatProgression", function (val) {
  if (!isNaN(val)) return ["Load", "Rep", "Set", "Golvend", "RPE"][val];
  else return val;
});

Vue.filter("formatChangeweight", function (val) {
  if (!isNaN(val)) return ["Ja", "Nee"][val];
  else return val;
});

//commaNumber
Vue.filter("commaNumber", function (val) {
  if (isNaN(val)) return 0;
  let string = val.toString();
  return string.replace(".", ",");
});

//formatRecurringType
Vue.filter("formatRecurringType", function (val) {
  return [
    "Eenmalig",
    "Dagelijks",
    "Wekelijks",
    "Maandelijks",
    "Jaarlijks",
  ][val];
});

Vue.filter("readingTabTypeIndex", function (val) {
  if (isNaN(val)) {
    let readingTabTypes = [
      "General",
      "Length",
      "Food",
      "Sport",
      "Injuries",
      "Lifestyle",
      "Goals",
    ];
    return readingTabTypes.indexOf(val);
  } else return val;
});

Vue.filter("readingTabToString", function (val) {
  let readingTabTypes1 = [
    "Information",
    "Measurement",
    "Food",
    "Fitness",
    "Injury",
    "Lifestyle",
    "Goals",
  ];
  let readingTabTypes2 = [
    "Algemene Informatie",
    "Lengtes en gewicht",
    "Voeding",
    "Bewegen/Sport",
    "Blessures",
    "Leefstijl",
    "Doelen",
  ];

  let index = readingTabTypes1.indexOf(val);

  if (index < 0) return "Geen";

  return readingTabTypes2[index];
});

Vue.filter("formatInjuryType", function (val) {
  let array = [
    "Hoofd",
    "linker bovenarm + schouder",
    "Rechter bovenarm + schouder",
    "-",
    "linker onderarm",
    "linker hand",
    "-",
    "Rechter onderarm",
    "rechter hand",
    "Borst regio",
    "Buik regio",
    "linker bovenbeen",
    "linker onderbeen",
    "linker voet",
    "rechter bovenbeen",
    "rechter onderbeen",
    "rechter voet",
    "-",
    "-",
    "-",
  ];
  val = String(val);

  return array[val];
});

Vue.filter("parseChatMessage", function (val) {
  var regex = /\[(.*?)\]/;
  //var regex = /(?<=\[)(.*?)(?=\])/;

  if (val == undefined) return "";
  var matched = regex.exec(val);

  //console.log("matched", matched);

  if (matched != null) {
    var splitArray = matched[1].split(":");
    let replaceId = splitArray[0].toLocaleLowerCase();
    var newString = "";
    switch (replaceId) {
      case "user_training_option_id":
        newString = val.replace(
          "[" + matched[1] + "]",
          "\n<span class='caption'>Klik onderaan dit bericht om naar de training te gaan</span>"
        );
        break;
      case "upload_id":
        newString = val.replace(
          "[" + matched[1] + "]",
          "\n<span class='caption'>Klik onderaan dit bericht om naar de upload te gaan</span>"
        );
        break;
      case "reading_id":
        newString = val.replace(
          "[" + matched[1] + "]",
          "\n<span class='caption'>Klik onderaan dit bericht om naar de meting te gaan</span>"
        );
        break;
      default:
        break;
    }

    return newString;
  } else {
    return val;
  }
});

Vue.filter("userObjectToFullname", function (obj) {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    if ("firstname" in obj && "lastname" in obj && "insertion" in obj) {
      let fullname =
        obj.insertion == null
          ? obj.firstname + " " + obj.lastname
          : obj.firstname + " " + obj.insertion + " " + obj.lastname;
      return fullname;
    } else {
      return "";
    }
  } else {
    return obj;
  }
});

Vue.filter("formatBoolToText", function (val, invert) {
  var valBool = val;
  if (typeof val == "string") valBool = val === "true";

  if (invert) valBool = !valBool;
  return valBool ? "Ja" : "Nee";
});

Vue.filter("arrayToString", function (val) {
  if (!Array.isArray(val)) return "";
  return val.join(", ");
});

function isValidTimestamp(_timestamp) {
  return moment(_timestamp, "X", true).isValid();
}
