/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  coachAppointments: {},
  subcoachAppointments: [],
  headCoachAppointments: [],
  coacheeAppointments: {},
  availableDates: [],
  appointmentTypes: [],
};

// getters
const getters = {
  getCoachAppointments: (state) => {
    return state.coachAppointments;
  },
  getSubcoachAppointments: (state) => {
    return state.subcoachAppointments;
  },
  getHeadCoachAppointments: (state) => {
    return state.headCoachAppointments;
  },
  getCoacheeAppointments: (state) => {
    return state.coacheeAppointments;
  },
  getAvailableDates: (state) => {
    return state.availableDates;
  },
  getAppointmentTypes: (state) => {
    return state.appointmentTypes;
  },
};

// actions
const actions = {
  //get all current coaches
  fetchCoachAppointments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coach")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_APPOINTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSubcoachAppointments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coach/subcoaches")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_SUBCOACH_APPOINTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchHeadCoachAppointments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coach/headcoach")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_HEADCOACH_APPOINTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoacheeAppointments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coachee")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACHEE_APPOINTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cancelCoacheeAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/" + payload.id, { reason: "test" })
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CANCEL_COACHEE_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cancelCoachAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/coach/" + payload.id, {
          reason: "test2",
          removeAllEvents: payload.removeAllEvents,
        })
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CANCEL_COACH_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addAppointmentSidenote({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/sidenote/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_COACH_APPOINTMENT_SIDENOTE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cancelCoachAvailableDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/coach/availabledates/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("CANCEL_COACH_AVAILABLE_DATE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCoacheeAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/appointments/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_COACHEE_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCoachAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/appointments/coach/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_COACH_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addAvailableDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/appointments/availableDates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_COACH_APPOINTMENTS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAvailableDates({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coachee/availabledates")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_AVAILABLE_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAppointmentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coach/types")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_APPOINTMENT_TYPES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //addNewAppointmentType
  addNewAppointmentType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/appointments/type", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_APPOINTMENT_TYPE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoacheeAppointmentTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/appointments/coachee/types")
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_APPOINTMENT_TYPES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //edit
  editCoachAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/coach/edit/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_COACH_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCoacheeAppointment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/coachee/edit/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_COACHEE_APPOINTMENT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editCoachAvailableDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/appointments/coach/edit/availableDate/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("EDIT_COACH_AVAILABLE_DATE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_APPOINTMENT_TYPES(state, data) {
    state.appointmentTypes = data;
  },
  FETCH_COACH_APPOINTMENTS(state, data) {
    state.coachAppointments = data;
  },
  FETCH_SUBCOACH_APPOINTMENTS(state, data) {
    state.subcoachAppointments = data;
  },
  FETCH_HEADCOACH_APPOINTMENTS(state, data) {
    state.headCoachAppointments = data;
  },
  FETCH_COACHEE_APPOINTMENTS(state, data) {
    state.coacheeAppointments = data;
  },
  FETCH_AVAILABLE_DATES(state, data) {
    state.availableDates = data;
  },
  CANCEL_COACHEE_APPOINTMENT(state, data) {
    let index = state.coacheeAppointments.appointmentViewModels.findIndex(
      (item) => item.id === data
    );
    state.coacheeAppointments.appointmentViewModels.splice(index, 1);
  },
  CANCEL_COACH_APPOINTMENT(state, data) {
    let index = state.coachAppointments.appointmentViewModels.findIndex(
      (item) => item.id === data
    );
    state.coachAppointments.appointmentViewModels.splice(index, 1);
  },
  CANCEL_COACH_AVAILABLE_DATE(state, data) {
    data.forEach((element) => {
      let index = state.coachAppointments.appointmentViewModels.findIndex(
        (item) => item.id == element
      );

      state.coachAppointments.appointmentViewModels.splice(index, 1);
    });
  },
  ADD_APPOINTMENT_TYPE(state, data) {
    state.appointmentTypes.unshift(data);
  },
  ADD_COACH_APPOINTMENT(state, data) {
    state.coachAppointments.appointmentViewModels = [
      ...state.coachAppointments.appointmentViewModels,
      ...data,
    ];
    //state.coachAppointments.appointmentViewModels.unshift(data);
  },
  ADD_COACHEE_APPOINTMENT(state, data) {
    state.coacheeAppointments.appointmentViewModels.unshift(data);
  },
  UPDATE_COACH_APPOINTMENT_SIDENOTE(state, data) {
    let index = state.coachAppointments.appointmentViewModels.findIndex(
      (item) => item.id === data.id
    );

    let obj = state.coachAppointments.appointmentViewModels[index];
    console.log(obj);

    obj.sideNote = data.sideNote;

    state.coachAppointments.appointmentViewModels.splice(
      index,
      1,
      obj
    );

    let obj2 = state.coachAppointments.appointmentViewModels[index];
    console.log(obj2, index);

    //state.coachAppointments.appointmentViewModels[index].sidenote = data.sidenote;
    //state.coachAppointments.appointmentViewModels.unshift(data);
  },
  EDIT_COACH_APPOINTMENT(state, data) {
    if (Array.isArray(data)) {
      data.forEach((element) => {
        var index = state.coachAppointments.appointmentViewModels.findIndex(
          function (item) {
            return item.id === element.id;
          }
        );

        if (index !== -1)
          state.coachAppointments.appointmentViewModels.splice(
            index,
            1,
            element
          );
        else {

          state.coachAppointments.appointmentViewModels.push(element);
        }
      });
    } else {
      var index = state.coachAppointments.appointmentViewModels.findIndex(
        function (item) {
          return item.id === data.id;
        }
      );

      if (index !== -1)
        state.coachAppointments.appointmentViewModels.splice(index, 1, data);
    }
  },
  EDIT_COACHEE_APPOINTMENT(state, data) {
    if (Array.isArray(data)) {
      data.forEach((element) => {
        var index = state.coacheeAppointments.appointmentViewModels.findIndex(
          function (item) {
            return item.id === element.id;
          }
        );

        if (index !== -1)
          state.coacheeAppointments.appointmentViewModels.splice(
            index,
            1,
            element
          );
        else {

          state.coacheeAppointments.appointmentViewModels.push(element);
        }
      });
    } else {
      var index = state.coacheeAppointments.appointmentViewModels.findIndex(
        function (item) {
          return item.id === data.id;
        }
      );

      if (index !== -1)
        state.coacheeAppointments.appointmentViewModels.splice(index, 1, data);
    }
  },
  EDIT_COACH_AVAILABLE_DATE(state, data) {
    var regularModels = data.appointmentViewModels;
    var removeIds = data.removeIds;
    if (Array.isArray(regularModels)) {
      regularModels.forEach((element) => {
        let index = state.coachAppointments.appointmentViewModels.findIndex(
          (item) => item.id == element.id
        );

        if (index !== -1)
          state.coachAppointments.appointmentViewModels.splice(
            index,
            1,
            element
          );
        else {

          state.coachAppointments.appointmentViewModels.push(element);
        }
      });

      //remove old
      removeIds.forEach(element => {
        let index = state.coachAppointments.appointmentViewModels.findIndex(
          (item) => item.id == element
        );

        state.coachAppointments.appointmentViewModels.splice(index, 1);
      });
    } else {
      var index = state.coachAppointments.appointmentViewModels.findIndex(
        function (item) {
          return item.id === regularModels.id;
        }
      );

      if (index !== -1)
        state.coachAppointments.appointmentViewModels.splice(index, 1, data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
