/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  currentProgression: {},
  currentBodyComp: {},
  currentBodyCompSettings: {},
  zeroReading: {},
  currentBodyCompValues: {},
  readingFeedback: [],
  firstBodyComp: {},
};

// getters
const getters = {
  getCurrentProgression: (state) => {
    return state.currentProgression;
  },
  getCurrentBodyComp: (state) => {
    return state.currentBodyComp;
  },
  getCurrentBodyCompSettings: (state) => {
    return state.currentBodyCompSettings;
  },
  getZeroReading: (state) => {
    return state.zeroReading;
  },
  getCurrentBodyCompValues: (state) => {
    return state.currentBodyCompValues;
  },
  getReadingFeedback: (state) => {
    return state.readingFeedback;
  },
  getFirstBodyComp: (state) => {
    return state.firstBodyComp;
  },
};

// actions
const actions = {
  createReadingBodyComp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/bodycomposition", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADING", res.data);
          commit("EMPTY_CURRENT_BODYCOMP_VALUES");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createReadingBodyCompAsCoach({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/bodycomposition/" + payload.userId, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADING", res.data);
          commit("EMPTY_CURRENT_BODYCOMP_VALUES");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createBodyCompSetting({ commit }, payload) {
    let id = payload.Id;
    delete payload.Id;
    return new Promise((resolve, reject) => {
      axios
        .put("api/UserSettings/bodycomposition/" + id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_ZEROREADING", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBodyCompProgression({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/progression")
        .then((res) => {
          resolve(res.data);
          commit("FETCH_PROGRESSION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCurrentBodyComp({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/current")
        .then((res) => {
          commit("FETCH_CURRENT", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFirstOwnCurrentBodyComp({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/first")
        .then((res) => {
          commit("FETCH_FIRST", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFirstCurrentBodyComp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/first/" + payload.userId)
        .then((res) => {
          commit("FETCH_FIRST", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCurrentBodyCompSetting({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Usersettings/bodycomposition/" + id)
        .then((res) => {
          commit("FETCH_CURRENT_SETTINGS", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getZeroReading({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/zero")
        .then((res) => {
          commit("FETCH_ZERO_READING", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getZeroReadingByUserId({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/zero/" + userId)
        .then((res) => {
          commit("FETCH_ZERO_READING", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //coach
  getUserBodyCompProgression({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/progression/" + payload.userId)
        .then((res) => {
          resolve(res.data);
          commit("FETCH_PROGRESSION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserBodyComp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/bodycomposition/current/" + payload.userId)
        .then((res) => {
          commit("FETCH_CURRENT", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setCurrentBodyCompValues({ commit }, data) {
    commit("SET_CURRENT_BODYCOMP_VALUES", data);
  },
  //feedback
  createReadingFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/Feedback", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          //console.log("res.data", res.data);
          commit("CREATE_READING_FEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchReadingFeedbackByUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Readings/feedback/" + payload.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("FETCH_READING_FEEDBACK", res.data);
        })
        .catch((err) => {
          commit("FETCH_READING_FEEDBACK", []);
          reject(err);
        });
    });
  },
  rateReadingFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Readings/Feedback/rate", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("UPDATE_READING_FEEDBACK", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  CREATE_READING(state, data) {
    state.registrations.unshift(data);
  },
  FETCH_PROGRESSION(state, data) {
    state.currentProgression = data;
  },
  FETCH_CURRENT(state, data) {
    state.currentBodyComp = data;
  },
  FETCH_ZERO_READING(state, data) {
    state.zeroReading = data;
  },
  FETCH_FIRST(state, data) {
    state.firstBodyComp = data;
  },
  FETCH_CURRENT_SETTINGS(state, data) {
    // console.log("new data = ", data);
    state.currentBodyCompSettings = data;
  },
  SET_CURRENT_BODYCOMP_VALUES(state, data) {
    state.currentBodyCompValues = data;
  },
  EMPTY_CURRENT_BODYCOMP_VALUES(state) {
    state.currentBodyCompValues = {};
  },
  CREATE_READING_FEEDBACK(state, data) {
    state.readingFeedback.unshift(data);
  },
  FETCH_READING_FEEDBACK(state, data) {
    state.readingFeedback = data;
  },
  UPDATE_READING_FEEDBACK(state, data) {
    state.readingFeedback = [
      ...state.readingFeedback.filter((element) => element.id !== data.id),
      data,
    ];
    state.readingFeedback.sort((a, b) => b.sendtime.localeCompare(a.sendtime));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
