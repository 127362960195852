/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  currentSubscription: {},
};

// getters
const getters = {
  getCurrentSubscription: (state) => {
    return state.currentSubscription;
  },
};

// actions
const actions = {
  //get all new registration
  fetchSubscription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Subscriptions/" + payload.userId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_SUBSCRIPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCoacheeSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Subscriptions/Coachee/")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_SUBSCRIPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteSubscription({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Subscriptions/delete/" + item.id)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("DELETE_SUBSCRIPTION");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addSubscription({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Subscriptions", item)
        .then((res) => {
          setTimeout(() => resolve(res.data), 500);
          commit("CREATE_SUBSCRIPTION", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_SUBSCRIPTION(state, data) {
    state.currentSubscription = data;
  },
  CREATE_SUBSCRIPTION(state, data) {
    state.currentSubscription = data;
  },
  DELETE_SUBSCRIPTION(state) {
    state.currentSubscription = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
