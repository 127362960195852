/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
//import axios from "axios";
//import Vue from "vue";

// initial state
const state = {
  localStorageItems: [],
  currentItem: {},
};

// getters
const getters = {
  getLocalStorageItems: (state) => {
    return state.localStorageItems;
  },
};

// actions
const actions = {
  //get all new registration
  setLocalStorageItem({ commit }, payload) {
    const name = payload.name;
    const item = payload.item;

    window.localStorage.setItem(name, JSON.stringify(item));

    commit("STORE_LOCAL_STORAGE", payload);
  },
  getLocalStorageItem({ commit }, payload) {
    const name = payload.name;

    let local = JSON.parse(window.localStorage.getItem(name));

    return new Promise((resolve) => {
      if (local == undefined || local != "") {
        setTimeout(() => resolve(local), 250);
        commit("GET_LOCAL_STORAGE", payload);
      } else setTimeout(() => resolve({}), 250);
    });
  },
  clearLocalStorageItem({ commit }, payload) {
    const name = payload.name;

    window.localStorage.removeItem(name);

    commit("CLEAR_LOCAL_STORAGE", name);
  },
  clearAllLocalStorage({ commit }) {
    commit("CLEAR_ALL_LOCAL_STORAGE");
  },
};

// mutations
const mutations = {
  STORE_LOCAL_STORAGE(state, data) {
    let index = state.localStorageItems.findIndex((item) => item == data.name);
    if (index < 0) state.localStorageItems.push(data.name);

    window.localStorage.setItem("LOCAL_STORED_ITEMS", state.localStorageItems);
  },
  GET_LOCAL_STORAGE(state, data) {
    let index = state.localStorageItems.findIndex((item) => item == data.name);
    if (index < 0) state.currentItem = data.name;
  },
  CLEAR_LOCAL_STORAGE(state, name) {
    let local = window.localStorage.getItem("LOCAL_STORED_ITEMS");
    var localArr = local.split(",");
    var index = localArr.indexOf(name);
    if (index !== -1) {
      localArr.splice(index, 1);
    }

    let index2 = state.localStorageItems.findIndex((item) => item == name);

    if (index2 < 0) state.localStorageItems.splice(index2, 1);

    window.localStorage.setItem("LOCAL_STORED_ITEMS", localArr);
  },
  CLEAR_ALL_LOCAL_STORAGE(state) {
    let local = window.localStorage.getItem("LOCAL_STORED_ITEMS");
    if (local != null) {
      var localArr = local.split(",");

      localArr.forEach((element) => {
        window.localStorage.removeItem(element);
      });
      state.localStorageItems.forEach((element) => {
        window.localStorage.removeItem(element);
      });

      state.localStorageItems = [];
      window.localStorage.removeItem("LOCAL_STORED_ITEMS");
    }

  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
