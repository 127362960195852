export default {
  request: function (req, token) {
    if (req.url === this.options.refreshData.url) {
      //localstorage for app
      var storage = window.localStorage;
      var oldStorage = this.token(this.options.refreshTokenName);
      var value = storage.getItem(this.options.refreshTokenName);

      var realToken =
        value == undefined || value == "" || value == null ? oldStorage : value;

      // console.log(realToken, oldStorage, value);
      req.data = {
        refreshToken: realToken,
        accessToken: token,
      };
    }
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: "Bearer " + token,
    });
  },

  response: function (res) {
    //var headers = this.drivers.http.getHeaders.call(this, res),
    var token = res.data.token || res.data.accessToken;
    const resData = res.data || {};
    this.token(this.options.refreshTokenName, resData.refreshToken);

    //localstorage for app
    var storage = window.localStorage;

    // console.log(res, this.options, storage, window.location.href);

    if (token) {
      storage.setItem(this.options.refreshTokenName, resData.refreshToken);
      storage.setItem(this.options.tokenDefaultKey, token);

      token = token.split(/Bearer:?\s?/i);

      returnHomeOnRefresh(window.location.href, res.config);

      // console.log("return please");

      return token[token.length > 1 ? 1 : 0].trim();
    }
  },
};

function isNewlyInstancedSession(url) {
  // var lastThree = url.substr(url.length - 3);
  var lastTen = url.substr(url.length - 10);
  var lastEight = url.substr(url.length - 8);
  // console.log("lastThree", lastThree);
  // console.log("lastTen", lastTen);
  // console.log("lastEight", lastEight);
  return lastTen == "/fulllogin" || lastEight == "/#/login";
}

function returnHomeOnRefresh(url, config) {
  //check if new instance
  if (!isNewlyInstancedSession(url)) return null;
  else {
    //check if refresh request
    if (config.url == "/api/token/refresh" && config.method == "post") {
      //console should redirect
      // console.log("should redirect");

      var base_url = window.location.origin;

      // var host = window.location.host;

      // var pathArray = window.location.pathname.split("/");
      // console.log(base_url, host, pathArray);

      setTimeout(() => {
        // console.log("Should redirect to " + base_url + "/");
        window.location.replace(base_url + "/");
      }, 5);
    }
  }
}
