/*=========================================================================================
  File Name: content.js
  Description: Vuex store for content
  ----------------------------------------------------------------------------------------
  Author: Jelmar
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  loginStats: {},
  userStats: {},
};

// getters
const getters = {
  getLoginStats: (state) => {
    return state.loginStats;
  },
  getUserStats: (state) => {
    return state.userStats;
  },
};

// actions
const actions = {
  //get all new registration
  fetchLoginStats({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Login/stats")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_LOGIN_STATS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserStats({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Users/stats")
        .then((res) => {
          setTimeout(() => resolve(res.data), 1500);
          commit("FETCH_USER_STATS", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_LOGIN_STATS(state, data) {
    state.loginStats = data;
  },
  FETCH_USER_STATS(state, data) {
    state.userStats = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
